<!-- eslint-disable vue/no-v-html -->
<template>
  <v-row
    v-if="!isLoading"
    class="match-height"
  >
    <v-col cols="12">
      <app-card-code :title="`${$t('menu.products')} / ${$t('menu.hotels')} / ${$t('lbl.cargaLote')}`">
        <v-card-text>
          <v-row>
            <v-col
              cols="12"
              md="2"
            >
              <v-btn
                color="error"
                class="mb-4 me-3"
                @click="$router.push({ name: 'hotels-list' })"
              >
                <v-icon>{{ icons.mdiArrowLeft }}</v-icon>
                <span>{{ $t('btn.back') }}</span>
              </v-btn>
            </v-col>
            <v-col
              v-if="lote !== null"
              cols="12"
              md="2"
            >
              <v-btn
                color="primary"
                class="mb-4 me-3"
                :loading="processUpload"
                :disabled="!lote.listo"
                @click="acceptUpload()"
              >
                <span>{{ $t('btn.update') }}</span>
                <v-icon>{{ icons.mdiUpdate }}</v-icon>
              </v-btn>
            </v-col>
            <v-col
              v-if="lote !== null"
              cols="12"
              md="2"
            >
              <v-btn
                color="error"
                class="mb-4 me-3"
                @click="canceleUpload()"
              >
                <span>{{ $t('btn.canceleUpload') }}</span>
                <v-icon>{{ icons.mdiCancel }}</v-icon>
              </v-btn>
            </v-col>

            <v-spacer></v-spacer>

            <v-col
              v-if="existRestautration"
              cols="12"
              md="2"
              class="mr-5"
            >
              <v-btn
                color="primary"
                class="mb-4 me-3"
                outlined
                @click="$router.push({ name: 'hotels-upload-lote-restore' })"
              >
                <v-icon>mdi-file-restore-outline</v-icon>
                <span class="pl-2">{{ $t('btn.restaurarLote') }}</span>
              </v-btn>
            </v-col>
          </v-row>

          <v-form class="multi-col-validation">
            <v-row v-if="lote === null && !uploadingFile">
              <v-col
                cols="12"
                md="3"
              >
                <v-file-input
                  v-model="file"
                  accept=".ods, .xls, .xlsx, .csv"
                  exc
                  show-size
                  outlined
                  dense
                  :label="$t('lbl.file')"
                  hide-details
                  @change="setFile"
                ></v-file-input>
              </v-col>
              <v-col
                cols="12"
                md="12"
              >
                <h3 class="mb-3 text-center">
                  {{ $t('lbl.orderFileColumn') }} (.ods, .xls, .xlsx, .csv)
                </h3>
                <v-img
                  v-if="nameB2B === 'ttttt'"
                  contain
                  lazy-src="@/assets/img/placeholder_lote_hotel_ttttt.png"
                  src="@/assets/img/placeholder_lote_hotel_ttttt.png"
                >
                </v-img>
                <v-img
                  v-else
                  contain
                  lazy-src="@/assets/img/placeholder_lote_hotel.png"
                  src="@/assets/img/placeholder_lote_hotel.png"
                >
                </v-img>
                <!--<v-simple-table>
                  <template v-slot:default>
                    <thead>
                      <tr>
                        <th class="text-uppercase">
                          {{ $t('lbl.name') }}
                        </th>
                        <th class="text-uppercase">
                          {{ $t('menu.operador') }}
                        </th>
                        <th class="text-uppercase">
                          {{ $t('menu.marcaAsociada') }}
                        </th>
                        <th class="text-uppercase">
                          {{ $t('menu.category') }}
                        </th>
                        <th class="text-uppercase">
                          {{ $t('menu.typeDestination') }}
                        </th>
                        <th class="text-uppercase">
                          {{ $t('menu.intereses') }}
                        </th>
                        <th class="text-uppercase">
                          {{ $t('lbl.only_adult') }}
                        </th>
                        <th class="text-uppercase">
                          {{ $t('menu.edadMin') }}
                        </th>
                        <th class="text-uppercase">
                          {{ $t('lbl.service') }}
                        </th>
                        <th class="text-uppercase">
                          {{ $t('lbl.publico') }}
                        </th>
                        <th class="text-uppercase">
                          {{ $t('lbl.sugerido') }}
                        </th>
                      </tr>

                      <tr>
                        <th colspan="11"></th>
                      </tr>
                    </thead>
                  </template>
                </v-simple-table>

                <v-simple-table>
                  <template v-slot:default>
                    <thead>
                      <tr>
                        <th class="text-uppercase">
                          {{ $t('lbl.cantRoom') }}
                        </th>
                        <th class="text-uppercase">
                          {{ $t('lbl.cantRest') }}
                        </th>
                        <th class="text-uppercase">
                          {{ $t('lbl.cantBars') }}
                        </th>
                        <th class="text-uppercase">
                          {{ $t('lbl.cantPiscinas') }}
                        </th>
                        <th class="text-uppercase">
                          {{ $t('lbl.cantSalon') }}
                        </th>
                        <th class="text-uppercase">
                          {{ $t('lbl.description') }}
                        </th>
                        <th class="text-uppercase">
                          {{ $t('lbl.country') }}
                        </th>
                        <th class="text-uppercase">
                          {{ $t('lbl.provinceEstado') }}
                        </th>
                        <th class="text-uppercase">
                          {{ $t('lbl.locality') }}
                        </th>
                        <th class="text-uppercase">
                          {{ $t('lbl.address') }}
                        </th>
                      </tr>
                      <tr>
                        <th colspan="10"></th>
                      </tr>
                    </thead>
                  </template>
                </v-simple-table>

                <v-simple-table>
                  <template v-slot:default>
                    <thead>
                      <tr>
                        <th class="text-uppercase">
                          {{ $t('lbl.latitud') }}
                        </th>
                        <th class="text-uppercase">
                          {{ $t('lbl.longitud') }}
                        </th>
                        <th class="text-uppercase">
                          {{ $t('lbl.telefono') }}
                        </th>
                        <th class="text-uppercase">
                          Ext.
                        </th>
                        <th class="text-uppercase">
                          {{ $t('lbl.email') }}
                        </th>
                        <th class="text-uppercase">
                          Check-In
                        </th>
                        <th class="text-uppercase">
                          Check-Out
                        </th>
                      </tr>
                    </thead>
                  </template>
                </v-simple-table>-->
              </v-col>

              <v-col
                cols="12"
                md="3"
              >
                <h3 class="mb-3 mt-2 text-center">
                  {{ $t('lbl.descriptionCampos') }}
                </h3>
              </v-col>
              <v-col
                cols="12"
                md="1"
              >
                <!--<v-btn
                  color="primary"
                  class="mb-4 me-3"
                >
                  <v-icon>{{ icons.mdiFileCode }}</v-icon>
                  <span>{{ $t('lbl.dowloadExample') }}</span>
                </v-btn>-->
                <!--<vue-json-to-csv
                  :json-data="[
                    {
                      name: 'Hotel Sol Palmeras',
                      operador: 'Melia Hotels Cuba',
                      marcaAsociada: 'Sol',
                      category: '4',
                      tipoDestino: 'Sol y playa',
                      intereses:
                        'Viaje de familia,Reuniones y eventos,Sol y playas,Bodas y lunas de miel,Viaje todo incluido,Grupos de ocio e incentivos,Viajes de buceo,Viaje de parejas,Viaje para solteros',
                      onlyAdult: 'No',
                      edad: '',
                      servicios:
                        'Servicios de fax,Servicios médicos,Galería de arte,Tienda de artesanía,Mini mercado,Parqueo,Servicios de taxis,Renta de motos,Renta de autos,Servicio de internet,Wi-Fi en el área del lobby,Habitaciones para no fumadores,Facilidades para late check-out,Servicio de lavandería,Llamadas al doctor 24 hrs al día,Jardines,Tiendas de souvenirs y regalos,Servicio de shuttle,Cuarto de equipaje,Grupo de seguridad interna 24 horas,Traslados privados en autos,Jacuzzi,Accesos a la playa,Sombrillas de playa y piscina,Duchas de playa y piscina,Tumbonas de playa y piscina,Servicio de salvavidas en la playa,Buro de turismo,Servicio de niñera,Paquetes de bodas,Gazebos para bodas,Paquetes de lunas de miel,Servicio de masajes,Sala de fitness climatizada,Solárium,Tratamientos de belleza,Idiomas del personal: Español, Inglés, Italiano, Francés y Alemán,No se aceptan mascotas,Programa de animación diaria,Música en vivo en la cena,Clases de español,Espectáculos de animación,Espectáculos profesionales',
                      rooms: '608',
                      restaurants: '9',
                      bars: '9',
                      pools: '3',
                      salones: '2',
                      description:
                        'El Hotel Sol Palmeras es un bonito y acogedor Todo Incluido ubicado en Varadero, una de las playas más hermosas de la Isla. Con una posición privilegiada frente al mar, ofrece a sus huéspedes increíbles vistas de un espectacular paisaje y el agradable fresco de la brisa marina. Está diseñado en forma de herradura lo cual permite alcanzar mejor visibilidad de todas las atracciones que le rodean. Al respecto, el Sol Palmeras se encuentra próximo al Centro Comercial Plaza Américas y al Varadero Golf Club, y ello lo convierte en el hospedaje por excelencia para golfistas y sus familias.',
                      country: 'Cuba',
                      state: 'Matanzas',
                      city: 'Varadero',
                      address: 'Carretera Las Morlas, Varadero, Matanzas, Cuba. CP 42 200',
                      latitud: '23.176433',
                      longitud: '-81.201992',
                      phones: '+5345667009, +5345667010x324x325',
                      emails: 'sol.palmeras@meliacuba.com',
                      checkIn: '15:00',
                      checkOut: '12:00',
                      habitaciones:
                        'Standard,Superior,Junior Suite,Suite,Bungalow (1 Habitación),Junior Suite Familiar,Bungalow Suite,Bungalow Familiar',
                    },
                  ]"
                  :labels="{
                    name: { title: 'Nombre' },
                    operador: { title: 'Operador' },
                    marcaAsociada: { title: 'Marca Asociada' },
                    category: { title: 'Categoría' },
                    tipoDestino: { title: 'Tipo de Destinos' },
                    intereses: { title: 'Intereses' },
                    onlyAdult: { title: 'Sólo Adultos' },
                    edad: { title: 'Edad' },
                    servicios: { title: 'Servicios' },
                    rooms: { title: 'Rooms' },
                    restaurants: { title: 'Restaurant' },
                    bars: { title: 'Bar' },
                    pools: { title: 'Pool' },
                    salones: { title: 'Salones' },
                    description: { title: 'Descripción' },
                    country: { title: 'País' },
                    state: { title: 'Provincia/Estado' },
                    city: { title: 'Localidad' },
                    address: { title: 'Dirección' },
                    latitud: { title: 'Latitud,' },
                    longitud: { title: 'Longitud' },
                    phones: { title: 'Teléfono' },
                    emails: { title: 'Email' },
                    checkIn: { title: 'Entrada' },
                    checkOut: { title: 'Salida' },
                    habitaciones: { title: 'Habitaciones' },
                  }"
                  csv-title="Export"
                  separator=";"
                >
                  <v-btn
                    color="primary"
                    class="mb-4 me-3"
                  >
                    <v-icon>{{ icons.mdiFileDownload }}</v-icon>
                    <span>{{ $t('lbl.dowloadExample') }}</span>
                  </v-btn>
                </vue-json-to-csv>-->

                <v-btn
                  color="primary"
                  class="mb-4 me-3"
                  @click="dowloadExportCsv"
                >
                  <v-icon>{{ icons.mdiFileDownload }}</v-icon>
                  <span>{{ $t('lbl.fileDowloadExample', { ext: 'XLS' }) }}</span>
                </v-btn>
              </v-col>

              <v-col
                cols="12"
                md="12"
              >
                <ol>
                  <li><b>Nombre</b>: nombre del hotel, <u>ejemplo</u>: <i>Sol Palmeras</i></li>
                  <li>
                    <b>Operador</b>: cadena encargada de la administración del hotel, <u>ejemplo</u>:
                    <i>Meliá Hotels Cuba</i>
                  </li>
                  <li>
                    <b>Marca asociada</b>: marca comercial que utiliza el operador para segmentar su producto,
                    <u>ejemplo</u>: <i>Sol, otras marcas de Melia Hotels Internacional son: Melia, Tryp</i>
                  </li>
                  <li><b>Categoría</b>: se refiere a la categoría del hotel en estrellas, <u>ejemplo</u>: <i>5</i></li>
                  <li>
                    <b>Tipo de Destinos</b>: tipo de destino donde se ubica el hotel, <u>ejemplo</u>:
                    <i>sol y playa, naturaleza, ciudad</i>
                  </li>
                  <li>
                    <b>Intereses</b>: definidos por el operador definen al tipo de cliente que están orientados los
                    servicios del hotel, <u>ejemplo</u>:
                    <i>Viaje de familia, Reuniones y eventos, Sol y playas, Vacaciones románticas, Bodas y lunas de
                      miel, Viaje todo incluido, Viaje para solteros, etc.</i>
                  </li>
                  <!--<li><b>Sólo Adultos</b>: indicar si es Sólo Adultos, <u>ejemplo</u>: <i>Si/No</i></li>
                  <li><b>Edad</b>: edad mínima del Sólo Adultos, <u>ejemplo</u>: <i>18</i></li>-->
                  <li>
                    <b>Servicios</b>: servicios incluidos en el hotel, <u>ejemplo</u>:
                    <i>Servicios de fax, Servicios médicos, Galería de arte, Tienda de artesanía, Mini mercado, Parqueo,
                      Servicios de taxis, Renta de motos, Renta de autos, Servicio de internet, Wi-Fi en el área del
                      lobby, Habitaciones para no fumadores, Facilidades para late check-out, Servicio de lavandería,
                      Llamadas al doctor 24 hrs al día, Tiendas de souvenirs y regalos, Servicio de shuttle, Cuarto de
                      equipaje, Traslados privados en autos, Jacuzzi, Accesos a la playa, Sombrillas de playa y piscina,
                      Duchas de playa y piscina, Tumbonas de playa y piscina, Servicio de salvavidas en la playa, Buro
                      de turismo, Servicio de niñera, Paquetes de bodas</i>
                  </li>
                  <li><b>Room</b>: cantidad de habitaciones del hotel, <u>ejemplo</u>: <i>345</i></li>
                  <li><b>Restaurant</b>: cantidad de restaurantes del hotel, <u>ejemplo</u>: <i>7</i></li>
                  <li><b>Bar</b>: cantidad de bares del hotel, <u>ejemplo</u>: <i>5</i></li>
                  <li><b>Pool</b>: cantidad de piscinas del hotel, <u>ejemplo</u>: <i>3</i></li>
                  <li><b>Salones</b>: cantidad de salones del hotel, <u>ejemplo</u>: <i>5</i></li>
                  <li>
                    <b>Descripción</b>: descripción general del hotel, <u>ejemplo</u>:
                    <i>El Hotel Sol Palmeras es un bonito y acogedor Todo Incluido ubicado en Varadero, una de las
                      playas más hermosas de la Isla. Con una posición privilegiada frente al mar, ofrece a sus
                      huéspedes increíbles vistas de un espectacular paisaje y el agradable fresco de la brisa
                      marina...</i>
                  </li>
                  <li><b>País</b>: país donde está localizado el hotel, <u>ejemplo</u>: <i>Cuba</i></li>
                  <li>
                    <b>Provincia/Estado</b>: provincia o estado donde está localizado el hotel, <u>ejemplo</u>:
                    <i>Matanzas</i>
                  </li>
                  <li><b>Localidad</b>: localidad donde está localizado el hotel, <u>ejemplo</u>: <i>Varadero</i></li>
                  <li>
                    <b>Dirección</b>: dirección completa del hotel, <u>ejemplo</u>:
                    <i>Carretera Las Morlas, Varadero, Matanzas, Cuba. CP 42200</i>
                  </li>
                  <li><b>Latitud</b>: coordenada de geolocalización, <u>ejemplo</u>: <i>23.176433</i></li>
                  <li><b>Longitud</b>: coordenada de geolocalización, <u>ejemplo</u>: <i>-81.201992</i></li>
                  <li>
                    <b>Contactos</b>: los contactos del producto Hotel comienzan con el nombre del contacto<b><i>(es obligatorio)</i></b> seguidos de un asterisco <i>(*)</i>
                    <br />
                    <i>Ej: John Doe*</i>
                    <br /><br />
                    El número de teléfono <b><i>(no obligatorio)</i></b> va unido al nombre del contacto y justo después del asterisco <i>(*)</i>,
                    comienza con el código del país <i>(sin el signo de +)</i>. A continuación se deja un espacio en
                    blanco y se escribe el número de teléfono.
                    <br />
                    <i>Ej: John Doe*513 98754565</i>
                    <br /><br />
                    En caso de existir extensiones <b><i>(no obligatorio)</i></b> del número telefónico, estas se separarían por una <i>(x)</i>
                    y sin dejar espacio después del número telefónico. Pueden existir varias extensiones.
                    <br />
                    <i>Ej: John Doe*513 98754565x101x102</i>
                    <br /><br />
                    El o los emails <b><i>(no obligatorio)</i></b> van ubicados usando un punto y coma <i>(;)</i> al final de la cadena.
                    También pueden existir varios emails asociados al mismo contacto y estos se separían por una coma <i>(,)</i>.
                    <br />
                    <i>Ej: John Doe*513 98754565x101x102;contacto1@email.com, contacto2@email.com</i>
                    <br /><br />
                    Si queremos agregar varios contactos al mismo hotel se debe repetir el proceso anterior separando los contactos
                    con una barra invertida o backslash <i>(/)</i>.
                    <br />
                    <i>Ej: John Doe*513 98754565x101x102;contacto@email.com, soporte@email.com / Miguel Pérez*513 89654621, 513 56564541;comercial@gmail.com</i>
                    <br /><br />
                    Si no queremos incluir los teléfonos
                    <br />
                    <i>Ej: John Doe*;contacto@email.com, soporte@email.com</i>
                    <br /><br />
                    Si no queremos incluir los emails
                    <br />
                    <i>Ej: John Doe*513 98754565x101x102;</i>
                    <br />
                  </li>
                  <!--<li>
                    <b>Teléfono</b>: teléfonos del hotel separados por comas, que incluye el código de país seguido de un
                    espacio en blanco y a continuación el teléfono y las extensiones en caso de tenerlas, <u>ejemplo</u>:
                    <i>+534 5667009, +534 5667010x324x325</i>
                  </li>
                  <li><b>Email</b>: emails del hotel, en caso de existir varios se separan por comas, <u>ejemplo</u>: <i>sol.palmeras@meliacuba.com</i>, <i>comercial@meliacuba.com</i></li>-->
                  <li><b>Entrada</b>: hora de entrada al hotel, <u>ejemplo</u>: <i>16:00</i></li>
                  <li><b>Salida</b>: hora de salida del hotel, <u>ejemplo</u>: <i>12:00</i></li>
                  <li>
                    <b>Habitaciones</b>: nombres de los tipos de habitaciones del hotel separadas por comas,
                    <u>ejemplo</u>:
                    <i>Standard, Superior, Junior Suite, Suite,Bungalow (1 Habitación), Junior Suite Familiar, Bungalow
                      Suite, Bungalow Familiar</i>
                  </li>
                  <fragment v-if="nameB2B === 'ttttt'">
                    <li>
                      <b>Imágenes</b>: links de las imágenes del hotel en el CSV, separadas por comas, <u>ejemplo</u>:
                      <i>ejemplo:
                        23706/sol-palmeras-all-inclusive-bungalows-hotel-571.jpeg,23706/sol-palmeras-all-inclusive-bungalows-hotel-443.jpeg,23706/sol-palmeras-all-inclusive-bungalows-hotel-929.jpeg</i>
                    </li>
                    <li>
                      <b>Videos</b>: links de las imágenes del hotel en el CSV, separadas por comas, <u>ejemplo</u>:
                      <i>ejemplo:
                        23706/sol-palmeras-all-inclusive-bungalows-hotel-571.avi,23706/sol-palmeras-all-inclusive-bungalows-hotel-443.mpg</i>
                    </li>
                    <li>
                      <b>Id Externo</b>: id del hotel en la BD de Holiplus, sólo informativo, <u>ejemplo</u>: <i>33</i>
                    </li>
                  </fragment>
                </ol>
              </v-col>
            </v-row>

            <v-row v-if="uploadingFile">
              <v-col
                cols="12"
                md="4"
              ></v-col>
              <v-col
                cols="12"
                md="4"
              >
                <v-skeleton-loader type="card"></v-skeleton-loader>
              </v-col>
            </v-row>
            <v-row v-else>
              <fragment v-if="lote !== null">
                <v-col
                  cols="10"
                  offset-md="1"
                >
                  <v-expansion-panels>
                    <v-expansion-panel v-if="hotelsChange.length > 0">
                      <v-expansion-panel-header>
                        <template v-slot:default>
                          <v-row>
                            <v-col cols="2">
                              <h4 class="mt-2">
                                {{ $t('menu.hotels') }}
                              </h4>
                            </v-col>
                            <v-col cols="1">
                              <v-tooltip
                                top
                                color="error"
                              >
                                <template v-slot:activator="{ on, attrs }">
                                  <v-btn
                                    icon
                                    v-bind="attrs"
                                    v-on="on"
                                  >
                                    <v-icon color="error">
                                      {{ icons.mdiInformationOutline }}
                                    </v-icon>
                                  </v-btn>
                                </template>
                                <span>{{ $t('lbl.problemsHotels', { items: 'Hoteles' }) }}</span>
                              </v-tooltip>
                            </v-col>
                          </v-row>
                        </template>
                      </v-expansion-panel-header>
                      <v-expansion-panel-content>
                        <v-simple-table>
                          <template v-slot:default>
                            <tbody>
                              <tr
                                v-for="(item, index) in hotelsChange"
                                :key="index"
                              >
                                <td width="80%">
                                  {{ item }}
                                </td>
                                <td>
                                  <v-tooltip
                                    top
                                    color="primary"
                                  >
                                    <template v-slot:activator="{ on, attrs }">
                                      <v-btn
                                        icon
                                        v-bind="attrs"
                                        v-on="on"
                                        @click="modalHotel(item)"
                                      >
                                        <v-icon color="primary">
                                          {{ icons.mdiSquareEditOutline }}
                                        </v-icon>
                                      </v-btn>
                                    </template>
                                    <span>{{ $t('btn.edit') }}</span>
                                  </v-tooltip>
                                </td>
                              </tr>
                            </tbody>
                          </template>
                        </v-simple-table>
                      </v-expansion-panel-content>
                    </v-expansion-panel>

                    <v-expansion-panel v-if="operatorsChange.length > 0">
                      <v-expansion-panel-header>
                        <template v-slot:default>
                          <v-row>
                            <v-col cols="2">
                              <h4 class="mt-2">
                                {{ $t('menu.operador') }}
                              </h4>
                            </v-col>
                            <v-col cols="1">
                              <v-tooltip
                                top
                                color="error"
                              >
                                <template v-slot:activator="{ on, attrs }">
                                  <v-btn
                                    icon
                                    v-bind="attrs"
                                    v-on="on"
                                  >
                                    <v-icon color="error">
                                      {{ icons.mdiInformationOutline }}
                                    </v-icon>
                                  </v-btn>
                                </template>
                                <span>{{ $t('lbl.problemsOperador') }}</span>
                              </v-tooltip>
                            </v-col>
                          </v-row>
                        </template>
                      </v-expansion-panel-header>
                      <v-expansion-panel-content>
                        <v-simple-table>
                          <template v-slot:default>
                            <tbody>
                              <tr
                                v-for="(item, index) in operatorsChange"
                                :key="index"
                              >
                                <td width="80%">
                                  {{ item }}
                                </td>
                                <td>
                                  <v-tooltip
                                    top
                                    color="primary"
                                  >
                                    <template v-slot:activator="{ on, attrs }">
                                      <v-btn
                                        icon
                                        v-bind="attrs"
                                        v-on="on"
                                        @click="modalOperator(item)"
                                      >
                                        <v-icon color="primary">
                                          {{ icons.mdiSquareEditOutline }}
                                        </v-icon>
                                      </v-btn>
                                    </template>
                                    <span>{{ $t('btn.edit') }}</span>
                                  </v-tooltip>
                                </td>
                              </tr>
                            </tbody>
                          </template>
                        </v-simple-table>
                      </v-expansion-panel-content>
                    </v-expansion-panel>

                    <v-expansion-panel v-if="marcasAsociadaChange.length > 0">
                      <v-expansion-panel-header>
                        <template v-slot:default>
                          <v-row>
                            <v-col cols="2">
                              <h4 class="mt-2">
                                {{ $t('menu.marcaAsociada') }}
                              </h4>
                            </v-col>
                            <v-col cols="1">
                              <v-tooltip
                                top
                                color="error"
                              >
                                <template v-slot:activator="{ on, attrs }">
                                  <v-btn
                                    icon
                                    v-bind="attrs"
                                    v-on="on"
                                  >
                                    <v-icon color="error">
                                      {{ icons.mdiInformationOutline }}
                                    </v-icon>
                                  </v-btn>
                                </template>
                                <span>{{ $t('lbl.problemsMarcaAsociada') }}</span>
                              </v-tooltip>
                            </v-col>
                          </v-row>
                        </template>
                      </v-expansion-panel-header>
                      <v-expansion-panel-content>
                        <v-simple-table>
                          <template v-slot:default>
                            <tbody>
                              <tr
                                v-for="(item, index) in marcasAsociadaChange"
                                :key="index"
                              >
                                <td width="80%">
                                  {{ item }}
                                </td>
                                <td>
                                  <v-tooltip
                                    top
                                    color="primary"
                                  >
                                    <template v-slot:activator="{ on, attrs }">
                                      <v-btn
                                        icon
                                        v-bind="attrs"
                                        v-on="on"
                                        @click="modalMarcaAsociada(item)"
                                      >
                                        <v-icon color="primary">
                                          {{ icons.mdiSquareEditOutline }}
                                        </v-icon>
                                      </v-btn>
                                    </template>
                                    <span>{{ $t('btn.edit') }}</span>
                                  </v-tooltip>
                                </td>
                              </tr>
                            </tbody>
                          </template>
                        </v-simple-table>
                      </v-expansion-panel-content>
                    </v-expansion-panel>

                    <v-expansion-panel v-if="categoriasChange.length > 0">
                      <v-expansion-panel-header>
                        <template v-slot:default>
                          <v-row>
                            <v-col cols="2">
                              <h4 class="mt-2">
                                {{ $t('menu.category') }}
                              </h4>
                            </v-col>
                            <v-col cols="1">
                              <v-tooltip
                                top
                                color="error"
                              >
                                <template v-slot:activator="{ on, attrs }">
                                  <v-btn
                                    icon
                                    v-bind="attrs"
                                    v-on="on"
                                  >
                                    <v-icon color="error">
                                      {{ icons.mdiInformationOutline }}
                                    </v-icon>
                                  </v-btn>
                                </template>
                                <span>{{ $t('lbl.problemsCategoria') }}</span>
                              </v-tooltip>
                            </v-col>
                          </v-row>
                        </template>
                      </v-expansion-panel-header>
                      <v-expansion-panel-content>
                        <v-simple-table>
                          <template v-slot:default>
                            <tbody>
                              <tr
                                v-for="(item, index) in categoriasChange"
                                :key="index"
                              >
                                <td width="80%">
                                  <v-rating
                                    :length="parseInt(item, 10)"
                                    readonly
                                    size="35"
                                    :value="parseInt(item, 10)"
                                    color="primary"
                                  ></v-rating>
                                </td>
                                <td>
                                  <v-tooltip
                                    top
                                    color="primary"
                                  >
                                    <template v-slot:activator="{ on, attrs }">
                                      <v-btn
                                        icon
                                        v-bind="attrs"
                                        v-on="on"
                                        @click="modalCategoria(item)"
                                      >
                                        <v-icon color="primary">
                                          {{ icons.mdiSquareEditOutline }}
                                        </v-icon>
                                      </v-btn>
                                    </template>
                                    <span>{{ $t('btn.edit') }}</span>
                                  </v-tooltip>
                                </td>
                              </tr>
                            </tbody>
                          </template>
                        </v-simple-table>
                      </v-expansion-panel-content>
                    </v-expansion-panel>

                    <v-expansion-panel v-if="tiposDestinosChange.length > 0">
                      <v-expansion-panel-header>
                        <template v-slot:default>
                          <v-row>
                            <v-col cols="2">
                              <h4 class="mt-2">
                                {{ $t('menu.typeDestination') }}
                              </h4>
                            </v-col>
                            <v-col cols="1">
                              <v-tooltip
                                top
                                color="error"
                              >
                                <template v-slot:activator="{ on, attrs }">
                                  <v-btn
                                    icon
                                    v-bind="attrs"
                                    v-on="on"
                                  >
                                    <v-icon color="error">
                                      {{ icons.mdiInformationOutline }}
                                    </v-icon>
                                  </v-btn>
                                </template>
                                <span>{{ $t('lbl.problemsTipoDestino') }}</span>
                              </v-tooltip>
                            </v-col>
                          </v-row>
                        </template>
                      </v-expansion-panel-header>
                      <v-expansion-panel-content>
                        <v-simple-table>
                          <template v-slot:default>
                            <tbody>
                              <tr
                                v-for="(item, index) in tiposDestinosChange"
                                :key="index"
                              >
                                <td width="80%">
                                  {{ item }}
                                </td>
                                <td>
                                  <v-tooltip
                                    top
                                    color="primary"
                                  >
                                    <template v-slot:activator="{ on, attrs }">
                                      <v-btn
                                        icon
                                        v-bind="attrs"
                                        v-on="on"
                                        @click="modalTipoDestino(item)"
                                      >
                                        <v-icon color="primary">
                                          {{ icons.mdiSquareEditOutline }}
                                        </v-icon>
                                      </v-btn>
                                    </template>
                                    <span>{{ $t('btn.edit') }}</span>
                                  </v-tooltip>
                                </td>
                              </tr>
                            </tbody>
                          </template>
                        </v-simple-table>
                      </v-expansion-panel-content>
                    </v-expansion-panel>

                    <v-expansion-panel v-if="serviciosChange.length > 0">
                      <v-expansion-panel-header>
                        <template v-slot:default>
                          <v-row>
                            <v-col cols="2">
                              <h4 class="mt-2">
                                {{ $t('lbl.services') }}
                              </h4>
                            </v-col>
                            <v-col cols="1">
                              <v-tooltip
                                top
                                color="error"
                              >
                                <template v-slot:activator="{ on, attrs }">
                                  <v-btn
                                    icon
                                    v-bind="attrs"
                                    v-on="on"
                                  >
                                    <v-icon color="error">
                                      {{ icons.mdiInformationOutline }}
                                    </v-icon>
                                  </v-btn>
                                </template>
                                <span>{{ $t('lbl.problemsServicio') }}</span>
                              </v-tooltip>
                            </v-col>
                          </v-row>
                        </template>
                      </v-expansion-panel-header>
                      <v-expansion-panel-content>
                        <v-simple-table>
                          <template v-slot:default>
                            <tbody>
                              <tr
                                v-for="(item, index) in serviciosChange"
                                :key="index"
                              >
                                <td width="80%">
                                  {{ item }}
                                </td>
                                <td>
                                  <v-tooltip
                                    top
                                    color="primary"
                                  >
                                    <template v-slot:activator="{ on, attrs }">
                                      <v-btn
                                        icon
                                        v-bind="attrs"
                                        v-on="on"
                                        @click="modalServicios(item)"
                                      >
                                        <v-icon color="primary">
                                          {{ icons.mdiSquareEditOutline }}
                                        </v-icon>
                                      </v-btn>
                                    </template>
                                    <span>{{ $t('btn.edit') }}</span>
                                  </v-tooltip>
                                </td>
                              </tr>
                            </tbody>
                          </template>
                        </v-simple-table>
                      </v-expansion-panel-content>
                    </v-expansion-panel>

                    <v-expansion-panel v-if="interesesChange.length > 0">
                      <v-expansion-panel-header>
                        <template v-slot:default>
                          <v-row>
                            <v-col cols="2">
                              <h4 class="mt-2">
                                {{ $t('menu.intereses') }}
                              </h4>
                            </v-col>
                            <v-col cols="1">
                              <v-tooltip
                                top
                                color="error"
                              >
                                <template v-slot:activator="{ on, attrs }">
                                  <v-btn
                                    icon
                                    v-bind="attrs"
                                    v-on="on"
                                  >
                                    <v-icon color="error">
                                      {{ icons.mdiInformationOutline }}
                                    </v-icon>
                                  </v-btn>
                                </template>
                                <span>{{ $t('lbl.problemsIntereses') }}</span>
                              </v-tooltip>
                            </v-col>
                          </v-row>
                        </template>
                      </v-expansion-panel-header>
                      <v-expansion-panel-content>
                        <v-simple-table>
                          <template v-slot:default>
                            <tbody>
                              <tr
                                v-for="(item, index) in interesesChange"
                                :key="index"
                              >
                                <td width="80%">
                                  {{ item }}
                                </td>
                                <td>
                                  <v-tooltip
                                    top
                                    color="primary"
                                  >
                                    <template v-slot:activator="{ on, attrs }">
                                      <v-btn
                                        icon
                                        v-bind="attrs"
                                        v-on="on"
                                        @click="modalIntereses(item)"
                                      >
                                        <v-icon color="primary">
                                          {{ icons.mdiSquareEditOutline }}
                                        </v-icon>
                                      </v-btn>
                                    </template>
                                    <span>{{ $t('btn.edit') }}</span>
                                  </v-tooltip>
                                </td>
                              </tr>
                            </tbody>
                          </template>
                        </v-simple-table>
                      </v-expansion-panel-content>
                    </v-expansion-panel>

                    <v-expansion-panel v-if="roomsChange.length > 0">
                      <v-expansion-panel-header>
                        <template v-slot:default>
                          <v-row>
                            <v-col cols="2">
                              <h4 class="mt-2">
                                {{ $t('menu.habitaciones') }}
                              </h4>
                            </v-col>
                            <v-col cols="1">
                              <v-tooltip
                                top
                                color="error"
                              >
                                <template v-slot:activator="{ on, attrs }">
                                  <v-btn
                                    icon
                                    v-bind="attrs"
                                    v-on="on"
                                  >
                                    <v-icon color="error">
                                      {{ icons.mdiInformationOutline }}
                                    </v-icon>
                                  </v-btn>
                                </template>
                                <span>{{ $t('lbl.problemsHabitaciones') }}</span>
                              </v-tooltip>
                            </v-col>
                          </v-row>
                        </template>
                      </v-expansion-panel-header>
                      <v-expansion-panel-content>
                        <v-simple-table>
                          <template v-slot:default>
                            <tbody>
                              <tr
                                v-for="(item, index) in roomsChange"
                                :key="index"
                              >
                                <td width="80%">
                                  {{ item }}
                                </td>
                                <td>
                                  <v-tooltip
                                    top
                                    color="primary"
                                  >
                                    <template v-slot:activator="{ on, attrs }">
                                      <v-btn
                                        icon
                                        v-bind="attrs"
                                        v-on="on"
                                        @click="modalRoom(item)"
                                      >
                                        <v-icon color="primary">
                                          {{ icons.mdiSquareEditOutline }}
                                        </v-icon>
                                      </v-btn>
                                    </template>
                                    <span>{{ $t('btn.edit') }}</span>
                                  </v-tooltip>
                                </td>
                              </tr>
                            </tbody>
                          </template>
                        </v-simple-table>
                      </v-expansion-panel-content>
                    </v-expansion-panel>
                  </v-expansion-panels>
                </v-col>

                <fragment v-if="lote.listo">
                  <v-col cols="12">
                    <v-simple-table>
                      <template v-slot:default>
                        <thead>
                          <tr>
                            <th class="text-uppercase">
                              {{ $t('lbl.name') }}
                            </th>
                            <th class="text-uppercase">
                              {{ $t('menu.operador') }}
                            </th>
                            <th class="text-uppercase">
                              {{ $t('menu.marcaAsociada') }}
                            </th>
                            <th class="text-uppercase">
                              {{ $t('menu.typeDestination') }}
                            </th>
                          </tr>
                        </thead>
                        <tbody>
                          <fragment
                            v-for="(item, index) in lote.lote"
                            :key="index"
                          >
                            <tr>
                              <td>
                                <fragment v-if="item.name.id > 0">
                                  {{ allHotels.filter(e => e.id === item.name.id)[0].name }}
                                </fragment>
                                <fragment v-else>
                                  {{ item.name.name }}
                                </fragment>
                                <!--<span v-if="item.edad !== null">
                                  <span v-if="item.edad.id !== null && item.edad.id > 0">
                                    ({{
                                      edades.filter(e => e.id === item.edad.id).length > 0
                                        ? edades.filter(e => e.id === item.edad.id)[0].name
                                        : ''
                                    }})
                                  </span>
                                </span>
                                <span
                                  v-if="
                                    item.solo_adulto === 'Si' || item.solo_adulto === 'Sí' || item.solo_adulto === 'si'
                                  "
                                >
                                  <v-chip
                                    class="ml-2"
                                    color="primary"
                                    text-color="white"
                                  >
                                    {{ $t('lbl.only_adult') }}
                                  </v-chip>
                                </span>-->
                                <v-btn
                                  icon
                                  @click="showItem(index)"
                                >
                                  <v-icon>
                                    {{ showRow && index === indexRow ? icons.mdiMenuDown : icons.mdiMenuRight }}
                                  </v-icon>
                                </v-btn>
                                <v-tooltip
                                  top
                                  color="error"
                                >
                                  <template v-slot:activator="{ on, attrs }">
                                    <v-btn
                                      icon
                                      v-bind="attrs"
                                      v-on="on"
                                      @click="deleteItem(item)"
                                    >
                                      <v-icon color="error">
                                        {{ icons.mdiDeleteOutline }}
                                      </v-icon>
                                    </v-btn>
                                  </template>
                                  <span>{{ $t('btn.delete') }}</span>
                                </v-tooltip>
                                <span v-if="item.categoria">
                                  <fragment v-if="categories.filter(e => e.id === item.categoria.id).length > 0">
                                    <v-rating
                                      :length="categories.filter(e => e.id === item.categoria.id)[0].cant_estrellas"
                                      readonly
                                      size="35"
                                      :value="categories.filter(e => e.id === item.categoria.id)[0].cant_estrellas"
                                      color="primary"
                                    ></v-rating>
                                    <span
                                      v-if="categories.filter(e => e.id === item.categoria.id)[0].plus"
                                      class="mr-2 pt-2"
                                    >PLUS</span>
                                  </fragment>
                                </span>
                              </td>
                              <td>
                                <span v-if="item.operador">
                                  <span v-if="item.operador.id">
                                    {{ allOperadores.filter(e => e.id === item.operador.id)[0].name }}
                                  </span>
                                </span>
                              </td>
                              <td>
                                <span v-if="item.marca_asociada">
                                  <span v-if="item.marca_asociada.id">
                                    {{ listMarcas.filter(e => e.id === item.marca_asociada.id)[0].name }}
                                  </span>
                                </span>
                              </td>
                              <td>
                                <span v-if="item.tipo_destino">
                                  <span v-if="item.tipo_destino.id">
                                    {{ allTipoDestino.filter(e => e.id === item.tipo_destino.id)[0].name }}
                                  </span>
                                </span>
                              </td>
                            </tr>
                            <tr v-if="showRow && index === indexRow">
                              <td colspan="4">
                                <v-row v-if="item.intereses">
                                  <v-col
                                    v-if="item.intereses.length > 0"
                                    cols="12"
                                    md="12"
                                  >
                                    <strong>{{ $t('menu.intereses') }}</strong><br />
                                    <span
                                      v-for="(inter, indInter) in item.intereses"
                                      :key="indInter"
                                    >
                                      <span v-if="inter.id">
                                        {{ intereses.filter(e => e.id === inter.id)[0].name }}
                                      </span>
                                      <span v-if="indInter <= item.intereses.length - 2">,</span>
                                    </span>
                                  </v-col>
                                </v-row>

                                <v-row v-if="item.servicios">
                                  <v-col
                                    v-if="item.servicios.length > 0"
                                    cols="12"
                                    md="12"
                                  >
                                    <strong>{{ $t('menu.servicios') }}</strong><br />
                                    <span
                                      v-for="(serv, indServ) in item.servicios"
                                      :key="indServ"
                                    >
                                      <span v-if="serv.id">
                                        {{ servicios.filter(e => e.id === serv.id)[0].name }}
                                      </span>
                                      <span v-if="indServ <= item.servicios.length - 2">,</span>
                                    </span>
                                  </v-col>
                                </v-row>

                                <v-row v-if="item.rooms">
                                  <v-col
                                    v-if="item.rooms.length > 0"
                                    cols="12"
                                    md="12"
                                  >
                                    <strong>{{ $t('menu.habitaciones') }}</strong><br />
                                    <span
                                      v-for="(serv, indServ) in item.rooms"
                                      :key="indServ"
                                    >
                                      <span v-if="serv.id">
                                        {{ allRooms.filter(e => e.id === serv.id)[0].name }}
                                      </span>
                                      <span v-if="indServ <= item.rooms.length - 2">,</span>
                                    </span>
                                  </v-col>
                                </v-row>

                                <v-row>
                                  <v-col
                                    v-if="item.cant_rooms"
                                    cols="12"
                                    md="2"
                                  >
                                    <b>{{ $t('lbl.cantRoom') }}</b>: {{ item.cant_rooms }}
                                  </v-col>
                                  <v-col
                                    v-if="item.cant_restaurant"
                                    cols="12"
                                    md="2"
                                  >
                                    <b>{{ $t('lbl.cantRest') }}</b>: {{ item.cant_restaurant }}
                                  </v-col>
                                  <v-col
                                    v-if="item.cant_bars"
                                    cols="12"
                                    md="2"
                                  >
                                    <b>{{ $t('lbl.cantBars') }}</b>: {{ item.cant_bars }}
                                  </v-col>
                                  <v-col
                                    v-if="item.cant_pools"
                                    cols="12"
                                    md="2"
                                  >
                                    <b>{{ $t('lbl.cantPiscinas') }}</b>: {{ item.cant_pools }}
                                  </v-col>
                                  <v-col
                                    v-if="item.cant_salon"
                                    cols="12"
                                    md="2"
                                  >
                                    <b>{{ $t('lbl.cantSalon') }}</b>: {{ item.cant_salon }}
                                  </v-col>
                                </v-row>

                                <v-row>
                                  <v-col
                                    cols="12"
                                    md="12"
                                  >
                                    <strong>{{ $t('lbl.destino') }}</strong><br />
                                    <span v-if="item.city">{{ item.city }}, </span>
                                    <span v-if="item.provincia">{{ item.provincia }}, </span>
                                    <span v-if="item.pais">{{ item.pais }}</span><br />
                                    <span v-if="item.lat && item.lng">
                                      <b>{{ $t('lbl.latitud') }}</b>: {{ ` ${item.lat}` }} <b>{{ $t('lbl.longitud') }}</b>: {{ ` ${item.lng}` }}<br />
                                    </span>
                                    <span v-if="item.direction">
                                      <strong>{{ $t('lbl.address') }}:</strong>
                                      <span>{{ ` ${item.direction}` }}</span><br />
                                    </span>
                                    <span v-if="item.contactos">
                                      <strong>{{ $t('lbl.contacts') }}:</strong><br />
                                      <span
                                        v-for="(contact, indT) in item.contactos"
                                        :key="indT"
                                      >
                                        {{ contact.name }}<br />
                                        <span
                                          v-for="(phone, indP) in contact.phones"
                                          :key="indP"
                                        >
                                          {{ phone.code }} {{ phone.phone }}
                                          <span v-if="phone.exts.length > 0">
                                            <fragment v-if="phone.exts[0].ext">
                                              Ext.
                                              <span
                                                v-for="(ext, indEx) in phone.exts"
                                                :key="indEx"
                                              >
                                                {{ ext.ext }}
                                                <span v-if="indEx < phone.exts.length - 1">, </span>
                                              </span>
                                            </fragment> </span><br />
                                        </span>
                                        <span v-if="contact.emails.length > 0">
                                          <span
                                            v-for="(email, indE) in contact.emails"
                                            :key="indE"
                                          >
                                            {{ email.email }}
                                            <span v-if="indE < contact.emails.length - 1">, </span>
                                          </span>
                                        </span>
                                        <br /><br />
                                      </span>
                                    </span>
                                    <span v-if="item.checkin">
                                      <strong> Checkin:</strong>
                                      <span>{{ ` ${item.checkin}` }}</span><br />
                                    </span>
                                    <span v-if="item.checkout">
                                      <strong> Checkout:</strong>
                                      <span>{{ ` ${item.checkout}` }}</span>
                                    </span>
                                  </v-col>
                                </v-row>

                                <v-row v-if="item.description">
                                  <v-col
                                    cols="12"
                                    md="12"
                                  >
                                    <strong>{{ $t('lbl.description') }}</strong><br />
                                    <p v-html="item.description"></p>
                                  </v-col>
                                </v-row>

                                <v-row v-if="item.id_externo">
                                  <v-col
                                    cols="12"
                                    md="12"
                                  >
                                    <v-chip
                                      class="ma-2"
                                      color="error"
                                    >
                                      <span>Id Externo: {{ item.id_externo }}</span>
                                    </v-chip>
                                  </v-col>
                                </v-row>

                                <v-row v-if="item.imagenes.length > 0">
                                  <v-col
                                    v-for="(img, indImg) in item.imagenes"
                                    :key="indImg"
                                    cols="12"
                                    md="3"
                                  >
                                    <v-img
                                      :src="nameB2B === 'ttttt' ? `${rutaS3}${img}` : img"
                                      max-height="150"
                                      max-width="250"
                                    >
                                    </v-img>
                                  </v-col>
                                </v-row>

                                <v-row v-if="item.videos.length > 0">
                                  <v-col
                                    v-for="(video, indVid) in item.videos"
                                    :key="indVid"
                                    cols="12"
                                    md="6"
                                  >
                                    <Media
                                      :kind="'video'"
                                      :controls="true"
                                      :muted="false"
                                      :autoplay="false"
                                      :src="[nameB2B === 'ttttt' ? `${rutaS3}${video}` : video]"
                                      :style="{ width: '500px' }"
                                    >
                                    </Media>
                                  </v-col>
                                </v-row>
                              </td>
                            </tr>
                          </fragment>
                        </tbody>
                      </template>
                    </v-simple-table>
                  </v-col>
                </fragment>
              </fragment>
            </v-row>
          </v-form>
        </v-card-text>
      </app-card-code>
    </v-col>

    <!-- MOSTRAR DELETE -->
    <v-dialog
      v-model="isDialogVisibleDeleteItem"
      scrollable
      max-width="380px"
    >
      <v-card
        v-if="model.name"
        max-height="400px"
      >
        <v-card-title>{{ `${$t('btn.delete')}` }}</v-card-title>
        <v-divider></v-divider>
        <v-card-text>{{ $t('msg.addDelete', { item: model.name.name }) }}</v-card-text>
        <v-divider></v-divider>

        <v-card-actions>
          <v-btn
            color="primary"
            outlined
            @click="isDialogVisibleDeleteItem = !isDialogVisibleDeleteItem"
          >
            {{ $t('btn.close') }}
          </v-btn>
          <v-spacer></v-spacer>
          <v-btn
            color="error"
            outlined
            :loading="loadingDelete"
            @click="confirmDelete()"
          >
            {{ $t('btn.send') }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!-- MOSTRAR UPDATE ALL -->
    <v-dialog
      v-model="isDialogUpdate"
      scrollable
      max-width="380px"
    >
      <v-card max-height="400px">
        <v-card-title>{{ `${$t('btn.acceptChange')}` }}</v-card-title>
        <v-divider></v-divider>
        <v-card-text>
          <p>{{ $t('msg.processUpload1') }}</p>
          <p>{{ $t('msg.processUpload2') }}</p>
        </v-card-text>
        <v-divider></v-divider>

        <v-card-actions>
          <v-btn
            color="primary"
            outlined
            @click="isDialogUpdate = !isDialogUpdate"
          >
            {{ $t('btn.close') }}
          </v-btn>
          <v-spacer></v-spacer>
          <v-btn
            color="error"
            outlined
            :loading="cancelarUpload"
            @click="confirmUpdateAll()"
          >
            {{ $t('btn.send') }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!-- MOSTRAR CANCEL UPLOAD -->
    <v-dialog
      v-model="isDialogVisibleDelete"
      scrollable
      max-width="380px"
    >
      <v-card max-height="400px">
        <v-card-title>{{ `${$t('btn.cancele')}` }}</v-card-title>
        <v-divider></v-divider>
        <v-card-text>{{ $t('msg.canceleUpload', { item: 'hotel' }) }}</v-card-text>
        <v-divider></v-divider>

        <v-card-actions>
          <v-btn
            color="primary"
            outlined
            @click="isDialogVisibleDelete = !isDialogVisibleDelete"
          >
            {{ $t('btn.close') }}
          </v-btn>
          <v-spacer></v-spacer>
          <v-btn
            color="error"
            outlined
            :loading="cancelarUpload"
            @click="confirmCancele()"
          >
            {{ $t('btn.send') }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!-- ADD CHANGE NOM -->
    <v-dialog
      v-model="isAddChange"
      scrollable
      max-width="380px"
    >
      <v-card max-height="400px">
        <v-card-title>{{ `${$t('btn.insert')}` }}</v-card-title>
        <v-divider></v-divider>
        <v-card-text>
          {{
            $t('msg.addChange', {
              item:
                type === 'operador'
                  ? model.operador.name
                  : type === 'marca_asociada'
                    ? model.marca_asociada.name
                    : type === 'categoria'
                      ? model.categoria.name
                      : type === 'tipo_destino'
                        ? model.tipo_destino.name
                        : type === 'servicios'
                          ? model.servicios.name
                          : type === 'intereses'
                            ? model.intereses.name
                            : type === 'rooms'
                              ? model.rooms.name
                              : type === 'hotel'
                                ? model.hotel.name
                                : '',
            })
          }}
        </v-card-text>
        <v-divider></v-divider>

        <v-card-actions>
          <v-btn
            color="primary"
            outlined
            @click="isAddChange = !isAddChange"
          >
            {{ $t('btn.close') }}
          </v-btn>
          <v-spacer></v-spacer>
          <v-btn
            color="error"
            outlined
            :loading="addChange"
            @click="confirmAddChange()"
          >
            {{ $t('btn.send') }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!--MODAL REMPLACE-->
    <v-dialog
      v-model="isAddRemplace"
      scrollable
      max-width="380px"
    >
      <v-card max-height="400px">
        <v-card-title>{{ `${$t('btn.remplace')}` }}</v-card-title>
        <v-divider></v-divider>
        <v-card-text>
          {{
            $t('msg.addRemplace', {
              remplace:
                type === 'operador'
                  ? model.operador.name
                  : type === 'marca_asociada'
                    ? model.marca_asociada.name
                    : type === 'categoria'
                      ? model.categoria.name
                      : type === 'tipo_destino'
                        ? model.tipo_destino.name
                        : type === 'servicios'
                          ? model.servicios.name
                          : type === 'intereses'
                            ? model.intereses.name
                            : type === 'rooms'
                              ? model.rooms.name
                              : type === 'hotel'
                                ? model.hotel.name
                                : '',
              item:
                type === 'operador'
                  ? model.operador.id
                    ? itemsOperadores.filter(e => e.id === model.operador.id).length > 0
                      ? itemsOperadores.filter(e => e.id === model.operador.id)[0].name
                      : ''
                    : ''
                  : type === 'marca_asociada'
                    ? model.marca_asociada.id
                      ? itemsMarcas.filter(e => e.id === model.marca_asociada.id).length > 0
                        ? itemsMarcas.filter(e => e.id === model.marca_asociada.id)[0].name
                        : ''
                      : ''
                    : type === 'categoria'
                      ? model.categoria.id
                        ? categories.filter(e => e.id === model.categoria.id).length > 0
                          ? categories.filter(e => e.id === model.categoria.id)[0].cant_estrellas
                          : ''
                        : ''
                      : type === 'tipo_destino'
                        ? model.tipo_destino.id
                          ? itemsTipoDestino.filter(e => e.id === model.tipo_destino.id).length > 0
                            ? itemsTipoDestino.filter(e => e.id === model.tipo_destino.id)[0].name
                            : ''
                          : ''
                        : type === 'servicios'
                          ? model.servicios.id
                            ? itemsServicios.filter(e => e.id === model.servicios.id).length > 0
                              ? itemsServicios.filter(e => e.id === model.servicios.id)[0].name
                              : ''
                            : ''
                          : type === 'intereses'
                            ? model.intereses.id
                              ? itemsIntereses.filter(e => e.id === model.intereses.id).length > 0
                                ? itemsIntereses.filter(e => e.id === model.intereses.id)[0].name
                                : ''
                              : ''
                            : type === 'rooms'
                              ? model.rooms.id
                                ? itemsRooms.filter(e => e.id === model.rooms.id).length > 0
                                  ? itemsRooms.filter(e => e.id === model.rooms.id)[0].name
                                  : ''
                                : ''
                              : type === 'hotel'
                                ? model.hotel.id
                                  ? itemsHotels.filter(e => e.id === model.hotel.id).length > 0
                                    ? itemsHotels.filter(e => e.id === model.hotel.id)[0].name
                                    : ''
                                  : ''
                                : '',
            })
          }}
        </v-card-text>
        <v-divider></v-divider>

        <v-card-actions>
          <v-btn
            color="primary"
            outlined
            @click="isAddRemplace = !isAddRemplace"
          >
            {{ $t('btn.close') }}
          </v-btn>
          <v-spacer></v-spacer>
          <v-btn
            color="error"
            outlined
            :loading="addChange"
            @click="confirmAddRemplace()"
          >
            {{ $t('btn.send') }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!--MODAL ASOCIATE-->
    <v-dialog
      v-model="isAddAsociate"
      scrollable
      max-width="380px"
    >
      <v-card max-height="400px">
        <v-card-title>{{ `${$t('btn.asociate')}` }}</v-card-title>
        <v-divider></v-divider>
        <v-card-text>
          {{
            $t('msg.addAsociate', {
              asociate:
                type === 'operador'
                  ? model.operador.name
                  : type === 'marca_asociada'
                    ? model.marca_asociada.name
                    : type === 'categoria'
                      ? model.categoria.name
                      : type === 'tipo_destino'
                        ? model.tipo_destino.name
                        : type === 'servicios'
                          ? model.servicios.name
                          : type === 'intereses'
                            ? model.intereses.name
                            : type === 'rooms'
                              ? model.rooms.name
                              : type === 'hotel'
                                ? model.hotel.name
                                : '',
              item:
                type === 'operador'
                  ? model.operador.id
                    ? itemsOperadores.filter(e => e.id === model.operador.id).length > 0
                      ? itemsOperadores.filter(e => e.id === model.operador.id)[0].name
                      : ''
                    : ''
                  : type === 'marca_asociada'
                    ? model.marca_asociada.id
                      ? itemsMarcas.filter(e => e.id === model.marca_asociada.id).length > 0
                        ? itemsMarcas.filter(e => e.id === model.marca_asociada.id)[0].name
                        : ''
                      : ''
                    : type === 'categoria'
                      ? model.categoria.id
                        ? categories.filter(e => e.id === model.categoria.id).length > 0
                          ? categories.filter(e => e.id === model.categoria.id)[0].cant_estrellas
                          : ''
                        : ''
                      : type === 'tipo_destino'
                        ? model.tipo_destino.id
                          ? itemsTipoDestino.filter(e => e.id === model.tipo_destino.id).length > 0
                            ? itemsTipoDestino.filter(e => e.id === model.tipo_destino.id)[0].name
                            : ''
                          : ''
                        : type === 'servicios'
                          ? model.servicios.id
                            ? itemsServicios.filter(e => e.id === model.servicios.id).length > 0
                              ? itemsServicios.filter(e => e.id === model.servicios.id)[0].name
                              : ''
                            : ''
                          : type === 'intereses'
                            ? model.intereses.id
                              ? itemsIntereses.filter(e => e.id === model.intereses.id).length > 0
                                ? itemsIntereses.filter(e => e.id === model.intereses.id)[0].name
                                : ''
                              : ''
                            : type === 'rooms'
                              ? model.rooms.id
                                ? itemsRooms.filter(e => e.id === model.rooms.id).length > 0
                                  ? itemsRooms.filter(e => e.id === model.rooms.id)[0].name
                                  : ''
                                : ''
                              : type === 'hotel'
                                ? model.hotel.id
                                  ? itemsHotels.filter(e => e.id === model.hotel.id).length > 0
                                    ? itemsHotels.filter(e => e.id === model.hotel.id)[0].name
                                    : ''
                                  : ''
                                : '',
            })
          }}
        </v-card-text>
        <v-divider></v-divider>

        <v-card-actions>
          <v-btn
            color="primary"
            outlined
            @click="isAddAsociate = !isAddAsociate"
          >
            {{ $t('btn.close') }}
          </v-btn>
          <v-spacer></v-spacer>
          <v-btn
            color="error"
            outlined
            :loading="addChange"
            @click="confirmAddAsociate()"
          >
            {{ $t('btn.send') }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!--MODAL DELETE VALUE-->
    <v-dialog
      v-model="isAddDelete"
      scrollable
      max-width="380px"
    >
      <v-card max-height="400px">
        <v-card-title>{{ `${$t('btn.delete')}` }}</v-card-title>
        <v-divider></v-divider>
        <v-card-text>
          {{
            $t('msg.addDelete', {
              item:
                type === 'operador'
                  ? model.operador.name
                  : type === 'marca_asociada'
                    ? model.marca_asociada.name
                    : type === 'categoria'
                      ? model.categoria.name
                      : type === 'tipo_destino'
                        ? model.tipo_destino.name
                        : type === 'servicios'
                          ? model.servicios.name
                          : type === 'intereses'
                            ? model.intereses.name
                            : type === 'rooms'
                              ? model.rooms.name
                              : type === 'hotel'
                                ? model.hotel.name
                                : '',
            })
          }}
        </v-card-text>
        <v-divider></v-divider>

        <v-card-actions>
          <v-btn
            color="primary"
            outlined
            @click="isAddDelete = !isAddDelete"
          >
            {{ $t('btn.close') }}
          </v-btn>
          <v-spacer></v-spacer>
          <v-btn
            color="error"
            outlined
            :loading="addChange"
            @click="confirmAddDelete()"
          >
            {{ $t('btn.send') }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <!-- CHANGE HOTEL -->
    <v-dialog
      v-model="isChangeHotel"
      max-width="750px"
    >
      <v-card height="250px">
        <v-card-title>{{ `${$t('lbl.gestionChange')}` }}</v-card-title>
        <v-divider></v-divider>
        <v-card-text>
          <v-row
            v-if="model.hotel"
            class="mt-5"
          >
            <v-col
              cols="12"
              md="6"
            >
              <v-text-field
                v-model="model.hotel.name"
                :label="$t('lbl.hotel')"
                outlined
                dense
                hide-details
              >
              </v-text-field>
            </v-col>
            <v-col
              cols="12"
              md="6"
            >
              <v-autocomplete
                v-model="model.hotel.id"
                :items="itemsHotels"
                :search-input.sync="searchHotel"
                hide-details
                hide-selected
                :label="$t('lbl.hotel')"
                outlined
                dense
                item-text="name"
                item-value="id"
                clearable
                single-line
              >
                <template v-slot:no-data>
                  <v-list-item>
                    <v-list-item-title>
                      {{ $t('lbl.hotel') }}
                    </v-list-item-title>
                  </v-list-item>
                </template>
                <template v-slot:selection="{ item }">
                  <span v-text="item.name"></span>
                </template>
                <template v-slot:item="{ item }">
                  <v-list-item-content>
                    <v-list-item-title>{{ item.name }}</v-list-item-title>
                  </v-list-item-content>
                </template>
              </v-autocomplete>
            </v-col>
          </v-row>
        </v-card-text>
        <v-divider></v-divider>

        <v-card-actions>
          <v-row class="ml-2">
            <v-col
              cols="12"
              md="2"
            >
              <v-btn
                color="primary"
                outlined
                @click="isChangeHotel = !isChangeHotel"
              >
                {{ $t('btn.close') }}
              </v-btn>
            </v-col>
            <v-col
              v-if="model.hotel"
              cols="12"
              md="6"
              offset-md="2"
            >
              <v-tooltip
                top
                color="primary"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    fab
                    x-small
                    v-bind="attrs"
                    color="primary"
                    outlined
                    v-on="on"
                    @click="showAdd('hotel')"
                  >
                    <v-icon>{{ icons.mdiPlus }}</v-icon>
                  </v-btn>
                </template>
                <span>{{ $t('btn.insert') }}</span>
              </v-tooltip>

              <v-tooltip
                top
                color="primary"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    fab
                    x-small
                    v-bind="attrs"
                    color="primary"
                    outlined
                    class="ml-5"
                    :disabled="!model.hotel.id"
                    v-on="on"
                    @click="showRemplace('hotel', model.hotel)"
                  >
                    <v-icon>{{ icons.mdiFileReplaceOutline }}</v-icon>
                  </v-btn>
                </template>
                <span>{{ $t('btn.remplace') }}</span>
              </v-tooltip>

              <v-tooltip
                top
                color="primary"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    fab
                    x-small
                    v-bind="attrs"
                    color="primary"
                    outlined
                    class="ml-5"
                    :disabled="!model.hotel.id"
                    v-on="on"
                    @click="showAsociate('hotel', model.hotel)"
                  >
                    <v-icon>{{ icons.mdiLinkVariantPlus }}</v-icon>
                  </v-btn>
                </template>
                <span>{{ $t('btn.asociate') }}</span>
              </v-tooltip>

              <v-tooltip
                top
                color="error"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    fab
                    x-small
                    v-bind="attrs"
                    color="error"
                    outlined
                    class="ml-5"
                    v-on="on"
                    @click="showDelete('hotel', model.hotel.name)"
                  >
                    <v-icon>{{ icons.mdiDeleteOutline }}</v-icon>
                  </v-btn>
                </template>
                <span>{{ $t('btn.delete') }}</span>
              </v-tooltip>
            </v-col>
          </v-row>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!-- CHANGE OPERADOR -->
    <v-dialog
      v-model="isChangeOperator"
      max-width="750px"
    >
      <v-card height="300px">
        <v-card-title>{{ `${$t('lbl.gestionChange')}` }}</v-card-title>
        <v-divider></v-divider>
        <v-card-text>
          <v-row
            v-if="model.operador"
            class="mt-5"
          >
            <v-col
              cols="12"
              md="6"
            >
              <v-text-field
                v-model="model.operador.name"
                :label="$t('menu.operador')"
                outlined
                dense
                hide-details
              >
              </v-text-field>
            </v-col>
            <v-col
              cols="12"
              md="6"
            >
              <v-autocomplete
                v-model="model.operador.id"
                :items="itemsOperadores"
                :search-input.sync="searchOperador"
                hide-details
                hide-selected
                :label="$t('menu.operador')"
                outlined
                dense
                item-text="name"
                item-value="id"
                clearable
                single-line
              >
                <template v-slot:no-data>
                  <v-list-item>
                    <v-list-item-title>
                      {{ $t('menu.operador') }}
                    </v-list-item-title>
                  </v-list-item>
                </template>
                <template v-slot:selection="{ item }">
                  <span v-text="item.name"></span>
                </template>
                <template v-slot:item="{ item }">
                  <v-list-item-content>
                    <v-list-item-title>{{ item.name }}</v-list-item-title>
                  </v-list-item-content>
                </template>
              </v-autocomplete>
            </v-col>
            <v-col
              cols="12"
              md="6"
            >
              <v-file-input
                v-model="logo"
                accept="image/*"
                show-size
                outlined
                dense
                label="Logo"
                hide-details
              ></v-file-input>
            </v-col>
          </v-row>
        </v-card-text>
        <v-divider></v-divider>

        <v-card-actions>
          <v-row class="ml-2">
            <v-col
              cols="12"
              md="2"
            >
              <v-btn
                color="primary"
                outlined
                @click="isChangeOperator = !isChangeOperator"
              >
                {{ $t('btn.close') }}
              </v-btn>
            </v-col>
            <v-col
              v-if="model.operador"
              cols="12"
              md="6"
              offset-md="2"
            >
              <v-tooltip
                top
                color="primary"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    fab
                    x-small
                    v-bind="attrs"
                    color="primary"
                    outlined
                    v-on="on"
                    @click="showAdd('operador')"
                  >
                    <v-icon>{{ icons.mdiPlus }}</v-icon>
                  </v-btn>
                </template>
                <span>{{ $t('btn.insert') }}</span>
              </v-tooltip>

              <v-tooltip
                top
                color="primary"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    fab
                    x-small
                    v-bind="attrs"
                    color="primary"
                    outlined
                    class="ml-5"
                    :disabled="!model.operador.id"
                    v-on="on"
                    @click="showRemplace('operador', model.operador)"
                  >
                    <v-icon>{{ icons.mdiFileReplaceOutline }}</v-icon>
                  </v-btn>
                </template>
                <span>{{ $t('btn.remplace') }}</span>
              </v-tooltip>

              <v-tooltip
                top
                color="primary"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    fab
                    x-small
                    v-bind="attrs"
                    color="primary"
                    outlined
                    class="ml-5"
                    :disabled="!model.operador.id"
                    v-on="on"
                    @click="showAsociate('operador', model.operador)"
                  >
                    <v-icon>{{ icons.mdiLinkVariantPlus }}</v-icon>
                  </v-btn>
                </template>
                <span>{{ $t('btn.asociate') }}</span>
              </v-tooltip>

              <v-tooltip
                top
                color="error"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    fab
                    x-small
                    v-bind="attrs"
                    color="error"
                    outlined
                    class="ml-5"
                    v-on="on"
                    @click="showDelete('operador', model.operador.name)"
                  >
                    <v-icon>{{ icons.mdiDeleteOutline }}</v-icon>
                  </v-btn>
                </template>
                <span>{{ $t('btn.delete') }}</span>
              </v-tooltip>
            </v-col>
          </v-row>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!-- CHANGE MARCA ASOCIADA -->
    <v-dialog
      v-model="isChangeMarca"
      max-width="750px"
    >
      <v-card height="300px">
        <v-card-title>{{ `${$t('lbl.gestionChange')}` }}</v-card-title>
        <v-divider></v-divider>
        <v-card-text>
          <v-row
            v-if="model.marca_asociada"
            class="mt-5"
          >
            <v-col
              cols="12"
              :md="model.marca_asociada.operador_id ? '6' : '12'"
            >
              <v-text-field
                v-model="model.marca_asociada.name"
                :label="$t('menu.marcaAsociada')"
                outlined
                dense
                hide-details
              >
              </v-text-field>
            </v-col>

            <v-col
              v-if="model.marca_asociada.operador_id"
              cols="12"
              md="6"
            >
              <v-autocomplete
                v-model="model.marca_asociada.id"
                :items="itemsMarcas"
                :search-input.sync="searchMarcas"
                hide-details
                hide-selected
                :label="$t('menu.marcaAsociada')"
                outlined
                dense
                item-text="name"
                item-value="id"
                clearable
                single-line
              >
                <template v-slot:no-data>
                  <v-list-item>
                    <v-list-item-title>
                      {{ $t('menu.marcaAsociada') }}
                    </v-list-item-title>
                  </v-list-item>
                </template>
                <template v-slot:selection="{ item }">
                  <span v-text="item.name"></span>
                </template>
                <template v-slot:item="{ item }">
                  <v-list-item-content>
                    <v-list-item-title>{{ item.name }}</v-list-item-title>
                  </v-list-item-content>
                </template>
              </v-autocomplete>
            </v-col>
            <v-col
              cols="12"
              md="6"
            >
              <v-autocomplete
                v-model="model.marca_asociada.operador_id"
                :items="itemsOperadores"
                :search-input.sync="searchOperador"
                hide-details
                hide-selected
                :label="$t('menu.operador')"
                outlined
                dense
                item-text="name"
                item-value="id"
                clearable
                single-line
                @change="setOperadores"
              >
                <template v-slot:no-data>
                  <v-list-item>
                    <v-list-item-title>
                      {{ $t('menu.operador') }}
                    </v-list-item-title>
                  </v-list-item>
                </template>
                <template v-slot:selection="{ item }">
                  <span v-text="item.name"></span>
                </template>
                <template v-slot:item="{ item }">
                  <v-list-item-content>
                    <v-list-item-title>{{ item.name }}</v-list-item-title>
                  </v-list-item-content>
                </template>
              </v-autocomplete>
            </v-col>
            <v-col
              cols="12"
              md="6"
            >
              <v-file-input
                v-model="logo"
                accept="image/*"
                show-size
                outlined
                dense
                label="Logo"
                hide-details
              ></v-file-input>
            </v-col>
          </v-row>
        </v-card-text>
        <v-divider></v-divider>

        <v-card-actions>
          <v-row class="ml-2">
            <v-col
              cols="12"
              md="2"
            >
              <v-btn
                color="primary"
                outlined
                @click="isChangeMarca = !isChangeMarca"
              >
                {{ $t('btn.close') }}
              </v-btn>
            </v-col>
            <v-col
              v-if="model.marca_asociada"
              cols="12"
              md="6"
              offset-md="2"
            >
              <v-tooltip
                top
                color="primary"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    fab
                    x-small
                    v-bind="attrs"
                    color="primary"
                    outlined
                    :disabled="!model.marca_asociada.operador_id"
                    v-on="on"
                    @click="showAdd('marca_asociada')"
                  >
                    <v-icon>{{ icons.mdiPlus }}</v-icon>
                  </v-btn>
                </template>
                <span>{{ $t('btn.insert') }}</span>
              </v-tooltip>

              <v-tooltip
                top
                color="primary"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    fab
                    x-small
                    v-bind="attrs"
                    color="primary"
                    outlined
                    class="ml-5"
                    :disabled="!model.marca_asociada.id || !model.marca_asociada.operador_id"
                    v-on="on"
                    @click="showRemplace('marca_asociada', model.marca_asociada)"
                  >
                    <v-icon>{{ icons.mdiFileReplaceOutline }}</v-icon>
                  </v-btn>
                </template>
                <span>{{ $t('btn.remplace') }}</span>
              </v-tooltip>

              <v-tooltip
                top
                color="primary"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    fab
                    x-small
                    v-bind="attrs"
                    color="primary"
                    outlined
                    class="ml-5"
                    :disabled="!model.marca_asociada.id || !model.marca_asociada.operador_id"
                    v-on="on"
                    @click="showAsociate('marca_asociada', model.marca_asociada)"
                  >
                    <v-icon>{{ icons.mdiLinkVariantPlus }}</v-icon>
                  </v-btn>
                </template>
                <span>{{ $t('btn.asociate') }}</span>
              </v-tooltip>

              <v-tooltip
                top
                color="error"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    fab
                    x-small
                    v-bind="attrs"
                    color="error"
                    outlined
                    class="ml-5"
                    v-on="on"
                    @click="showDelete('marca_asociada', model.marca_asociada.name)"
                  >
                    <v-icon>{{ icons.mdiDeleteOutline }}</v-icon>
                  </v-btn>
                </template>
                <span>{{ $t('btn.delete') }}</span>
              </v-tooltip>
            </v-col>
          </v-row>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!-- CHANGE CATEGORIA -->
    <v-dialog
      v-model="isChangeCategoria"
      max-width="750px"
    >
      <v-card height="220px">
        <v-card-title>{{ `${$t('lbl.gestionChange')}` }}</v-card-title>
        <v-divider></v-divider>
        <v-card-text>
          <v-row
            v-if="model.categoria"
            class="mt-5"
          >
            <v-col
              cols="12"
              md="6"
            >
              <v-text-field
                v-model="model.categoria.name"
                :label="$t('menu.category')"
                outlined
                dense
                hide-details
                type="number"
              >
              </v-text-field>
            </v-col>
            <v-col
              cols="12"
              md="6"
            >
              <v-select
                v-model="model.categoria.id"
                :items="categories"
                item-text="name"
                item-value="id"
                :label="$t('menu.category')"
                outlined
                dense
                hide-details
              >
                <template v-slot:selection="{ item }">
                  <span v-if="item.name !== null">
                    {{ item.name }}
                    <span v-if="item.plus">PLUS</span>
                  </span>
                  <span v-if="item.name === null">
                    <v-row>
                      <v-col
                        cols="12"
                        md="8"
                      >
                        <v-rating
                          v-model="item.cant_estrellas"
                          color="primary"
                          size="30"
                          readonly
                        ></v-rating>
                      </v-col>
                      <v-col
                        cols="12"
                        md="4"
                        class="mt-2"
                      >
                        <span v-if="item.plus">PLUS</span>
                      </v-col>
                    </v-row>
                  </span>
                </template>

                <template v-slot:item="{ item }">
                  <v-list-item-content>
                    <v-list-item-title v-if="item.name !== null">
                      {{ item.name }}
                      <span v-if="item.plus">PLUS</span>
                    </v-list-item-title>
                    <v-list-item-title v-if="item.name === null">
                      <v-row>
                        <v-col
                          cols="12"
                          md="8"
                        >
                          <v-rating
                            v-model="item.cant_estrellas"
                            color="primary"
                            readonly
                            size="30"
                          ></v-rating>
                        </v-col>
                        <v-col
                          cols="12"
                          md="2"
                        >
                          <p
                            v-if="item.plus"
                            class="pt-3"
                          >
                            PLUS
                          </p>
                        </v-col>
                      </v-row>
                    </v-list-item-title>
                  </v-list-item-content>
                </template>
              </v-select>
            </v-col>
          </v-row>
        </v-card-text>
        <v-divider></v-divider>

        <v-card-actions>
          <v-row class="ml-2">
            <v-col
              cols="12"
              md="2"
            >
              <v-btn
                color="primary"
                outlined
                @click="isChangeCategoria = !isChangeCategoria"
              >
                {{ $t('btn.close') }}
              </v-btn>
            </v-col>
            <v-col
              v-if="model.categoria"
              cols="12"
              md="6"
              offset-md="2"
            >
              <v-tooltip
                top
                color="primary"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    fab
                    x-small
                    v-bind="attrs"
                    color="primary"
                    outlined
                    v-on="on"
                    @click="showAdd('categoria')"
                  >
                    <v-icon>{{ icons.mdiPlus }}</v-icon>
                  </v-btn>
                </template>
                <span>{{ $t('btn.insert') }}</span>
              </v-tooltip>

              <v-tooltip
                top
                color="primary"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    fab
                    x-small
                    v-bind="attrs"
                    color="primary"
                    outlined
                    class="ml-5"
                    :disabled="!model.categoria.id"
                    v-on="on"
                    @click="showRemplace('categoria', model.categoria)"
                  >
                    <v-icon>{{ icons.mdiFileReplaceOutline }}</v-icon>
                  </v-btn>
                </template>
                <span>{{ $t('btn.remplace') }}</span>
              </v-tooltip>

              <v-tooltip
                top
                color="primary"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    fab
                    x-small
                    v-bind="attrs"
                    color="primary"
                    outlined
                    class="ml-5"
                    :disabled="!model.categoria.id"
                    v-on="on"
                    @click="showAsociate('categoria', model.categoria)"
                  >
                    <v-icon>{{ icons.mdiLinkVariantPlus }}</v-icon>
                  </v-btn>
                </template>
                <span>{{ $t('btn.asociate') }}</span>
              </v-tooltip>

              <v-tooltip
                top
                color="error"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    fab
                    x-small
                    v-bind="attrs"
                    color="error"
                    outlined
                    class="ml-5"
                    v-on="on"
                    @click="showDelete('categoria', model.categoria.name)"
                  >
                    <v-icon>{{ icons.mdiDeleteOutline }}</v-icon>
                  </v-btn>
                </template>
                <span>{{ $t('btn.delete') }}</span>
              </v-tooltip>
            </v-col>
          </v-row>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!-- CHANGE TIPO DESTINO -->
    <v-dialog
      v-model="isChangeTipoDestino"
      max-width="750px"
    >
      <v-card height="220px">
        <v-card-title>{{ `${$t('lbl.gestionChange')}` }}</v-card-title>
        <v-divider></v-divider>
        <v-card-text>
          <v-row
            v-if="model.tipo_destino"
            class="mt-5"
          >
            <v-col
              cols="12"
              md="6"
            >
              <v-text-field
                v-model="model.tipo_destino.name"
                :label="$t('menu.typeDestination')"
                outlined
                dense
                hide-details
              >
              </v-text-field>
            </v-col>
            <v-col
              cols="12"
              md="6"
            >
              <v-autocomplete
                v-model="model.tipo_destino.id"
                :items="itemsTipoDestino"
                :search-input.sync="searchTipoDestino"
                hide-details
                hide-selected
                :label="$t('menu.typeDestination')"
                outlined
                dense
                item-text="name"
                item-value="id"
                clearable
                single-line
              >
                <template v-slot:no-data>
                  <v-list-item>
                    <v-list-item-title>
                      {{ $t('menu.typeDestination') }}
                    </v-list-item-title>
                  </v-list-item>
                </template>
                <template v-slot:selection="{ item }">
                  <span v-text="item.name"></span>
                </template>
                <template v-slot:item="{ item }">
                  <v-list-item-content>
                    <v-list-item-title>{{ item.name }}</v-list-item-title>
                  </v-list-item-content>
                </template>
              </v-autocomplete>
            </v-col>
          </v-row>
        </v-card-text>
        <v-divider></v-divider>

        <v-card-actions>
          <v-row class="ml-2">
            <v-col
              cols="12"
              md="2"
            >
              <v-btn
                color="primary"
                outlined
                @click="isChangeTipoDestino = !isChangeTipoDestino"
              >
                {{ $t('btn.close') }}
              </v-btn>
            </v-col>
            <v-col
              v-if="model.tipo_destino"
              cols="12"
              md="6"
              offset-md="2"
            >
              <v-tooltip
                top
                color="primary"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    fab
                    x-small
                    v-bind="attrs"
                    color="primary"
                    outlined
                    v-on="on"
                    @click="showAdd('tipo_destino')"
                  >
                    <v-icon>{{ icons.mdiPlus }}</v-icon>
                  </v-btn>
                </template>
                <span>{{ $t('btn.insert') }}</span>
              </v-tooltip>

              <v-tooltip
                top
                color="primary"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    fab
                    x-small
                    v-bind="attrs"
                    color="primary"
                    outlined
                    class="ml-5"
                    :disabled="!model.tipo_destino.id"
                    v-on="on"
                    @click="showRemplace('tipo_destino', model.tipo_destino)"
                  >
                    <v-icon>{{ icons.mdiFileReplaceOutline }}</v-icon>
                  </v-btn>
                </template>
                <span>{{ $t('btn.remplace') }}</span>
              </v-tooltip>

              <v-tooltip
                top
                color="primary"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    fab
                    x-small
                    v-bind="attrs"
                    color="primary"
                    outlined
                    class="ml-5"
                    :disabled="!model.tipo_destino.id"
                    v-on="on"
                    @click="showAsociate('tipo_destino', model.tipo_destino)"
                  >
                    <v-icon>{{ icons.mdiLinkVariantPlus }}</v-icon>
                  </v-btn>
                </template>
                <span>{{ $t('btn.asociate') }}</span>
              </v-tooltip>

              <v-tooltip
                top
                color="error"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    fab
                    x-small
                    v-bind="attrs"
                    color="error"
                    outlined
                    class="ml-5"
                    v-on="on"
                    @click="showDelete('tipo_destino', model.tipo_destino.name)"
                  >
                    <v-icon>{{ icons.mdiDeleteOutline }}</v-icon>
                  </v-btn>
                </template>
                <span>{{ $t('btn.delete') }}</span>
              </v-tooltip>
            </v-col>
          </v-row>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!-- CHANGE SERVICIOS -->
    <v-dialog
      v-model="isChangeServicios"
      max-width="750px"
    >
      <v-card height="300px">
        <v-card-title>{{ `${$t('lbl.gestionChange')}` }}</v-card-title>
        <v-divider></v-divider>
        <v-card-text>
          <v-row
            v-if="model.servicios"
            class="mt-5"
          >
            <v-col
              cols="12"
              md="6"
            >
              <v-text-field
                v-model="model.servicios.name"
                :label="$t('lbl.service')"
                outlined
                dense
                hide-details
              >
              </v-text-field>
            </v-col>
            <v-col
              cols="12"
              md="6"
            >
              <v-autocomplete
                v-model="model.servicios.id"
                :items="itemsServicios"
                :search-input.sync="searchServicios"
                hide-details
                hide-selected
                :label="$t('lbl.service')"
                outlined
                dense
                item-text="name"
                item-value="id"
                clearable
                single-line
                @click:clear="clearServicio()"
                @change="setServicios()"
              >
                <template v-slot:no-data>
                  <v-list-item>
                    <v-list-item-title>
                      {{ $t('lbl.service') }}
                    </v-list-item-title>
                  </v-list-item>
                </template>
                <template v-slot:selection="{ item }">
                  <span v-text="item.name"></span>
                </template>
                <template v-slot:item="{ item }">
                  <v-list-item-content>
                    <v-list-item-title>{{ item.name }}</v-list-item-title>
                  </v-list-item-content>
                </template>
              </v-autocomplete>
            </v-col>

            <v-col
              v-if="iconSelect !== null && iconSelect !== ''"
              cols="12"
              md="1"
              class="text-center pt-4"
            >
              <v-icon
                v-if="model.servicios.icon"
                size="30"
                color="primary"
                v-text="iconSelect"
              ></v-icon>
            </v-col>
            <v-col
              v-if="model.servicios"
              cols="12"
              :md="iconSelect != null ? '5' : '6'"
            >
              <v-autocomplete
                v-model="model.servicios.icon"
                :items="itemsIcons"
                :search-input.sync="searchIcons"
                hide-details
                hide-selected
                label="Icon"
                outlined
                dense
                item-text="name"
                item-value="name"
                clearable
                single-line
                @click:clear="iconSelect = null"
                @change="changeIcons"
              >
                <template v-slot:no-data>
                  <v-list-item>
                    <v-list-item-title>
                      Icon
                    </v-list-item-title>
                  </v-list-item>
                </template>
                <template v-slot:selection="{ item }">
                  <span v-text="item.name"></span>
                </template>
                <template v-slot:item="{ item }">
                  <v-list-item-group color="primary">
                    <v-list-item>
                      <v-list-item-icon>
                        <v-icon v-text="item.name"></v-icon>
                      </v-list-item-icon>
                      <v-list-item-content>
                        <v-list-item-title class="pl-5">
                          {{ item.name }}
                        </v-list-item-title>
                      </v-list-item-content>
                    </v-list-item>
                  </v-list-item-group>
                </template>
              </v-autocomplete>
            </v-col>
          </v-row>
        </v-card-text>
        <v-divider></v-divider>

        <v-card-actions>
          <v-row
            v-if="model.servicios"
            class="ml-2"
          >
            <v-col
              cols="12"
              md="2"
            >
              <v-btn
                color="primary"
                outlined
                @click="isChangeServicios = !isChangeServicios"
              >
                {{ $t('btn.close') }}
              </v-btn>
            </v-col>
            <v-col
              cols="12"
              md="6"
              offset-md="2"
            >
              <v-tooltip
                top
                color="primary"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    fab
                    x-small
                    v-bind="attrs"
                    color="primary"
                    outlined
                    v-on="on"
                    @click="showAdd('servicios')"
                  >
                    <v-icon>{{ icons.mdiPlus }}</v-icon>
                  </v-btn>
                </template>
                <span>{{ $t('btn.insert') }}</span>
              </v-tooltip>

              <v-tooltip
                top
                color="primary"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    fab
                    x-small
                    v-bind="attrs"
                    color="primary"
                    outlined
                    class="ml-5"
                    :disabled="!model.servicios.id"
                    v-on="on"
                    @click="showRemplace('servicios', model.servicios)"
                  >
                    <v-icon>{{ icons.mdiFileReplaceOutline }}</v-icon>
                  </v-btn>
                </template>
                <span>{{ $t('btn.remplace') }}</span>
              </v-tooltip>

              <v-tooltip
                top
                color="primary"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    fab
                    x-small
                    v-bind="attrs"
                    color="primary"
                    outlined
                    class="ml-5"
                    :disabled="!model.servicios.id"
                    v-on="on"
                    @click="showAsociate('servicios', model.servicios)"
                  >
                    <v-icon>{{ icons.mdiLinkVariantPlus }}</v-icon>
                  </v-btn>
                </template>
                <span>{{ $t('btn.asociate') }}</span>
              </v-tooltip>

              <v-tooltip
                top
                color="error"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    fab
                    x-small
                    v-bind="attrs"
                    color="error"
                    outlined
                    class="ml-5"
                    v-on="on"
                    @click="showDelete('servicios', model.servicios.name)"
                  >
                    <v-icon>{{ icons.mdiDeleteOutline }}</v-icon>
                  </v-btn>
                </template>
                <span>{{ $t('btn.delete') }}</span>
              </v-tooltip>
            </v-col>
          </v-row>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!-- CHANGE INTERESES -->
    <v-dialog
      v-model="isChangeIntereses"
      max-width="750px"
    >
      <v-card height="250px">
        <v-card-title>{{ `${$t('lbl.gestionChange')}` }}</v-card-title>
        <v-divider></v-divider>
        <v-card-text>
          <v-row
            v-if="model.intereses"
            class="mt-5"
          >
            <v-col
              cols="12"
              md="6"
            >
              <v-text-field
                v-model="model.intereses.name"
                :label="$t('menu.intereses')"
                outlined
                dense
                hide-details
              >
              </v-text-field>
            </v-col>
            <v-col
              cols="12"
              md="6"
            >
              <v-autocomplete
                v-model="model.intereses.id"
                :items="itemsIntereses"
                :search-input.sync="searchIntereses"
                hide-details
                hide-selected
                :label="$t('menu.intereses')"
                outlined
                dense
                item-text="name"
                item-value="id"
                clearable
                single-line
              >
                <template v-slot:no-data>
                  <v-list-item>
                    <v-list-item-title>
                      {{ $t('menu.intereses') }}
                    </v-list-item-title>
                  </v-list-item>
                </template>
                <template v-slot:selection="{ item }">
                  <span v-text="item.name"></span>
                </template>
                <template v-slot:item="{ item }">
                  <v-list-item-content>
                    <v-list-item-title>{{ item.name }}</v-list-item-title>
                  </v-list-item-content>
                </template>
              </v-autocomplete>
            </v-col>
          </v-row>
        </v-card-text>
        <v-divider></v-divider>

        <v-card-actions>
          <v-row
            v-if="model.intereses"
            class="ml-2"
          >
            <v-col
              cols="12"
              md="2"
            >
              <v-btn
                color="primary"
                outlined
                @click="isChangeIntereses = !isChangeIntereses"
              >
                {{ $t('btn.close') }}
              </v-btn>
            </v-col>
            <v-col
              cols="12"
              md="6"
              offset-md="2"
            >
              <v-tooltip
                top
                color="primary"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    fab
                    x-small
                    v-bind="attrs"
                    color="primary"
                    outlined
                    v-on="on"
                    @click="showAdd('intereses')"
                  >
                    <v-icon>{{ icons.mdiPlus }}</v-icon>
                  </v-btn>
                </template>
                <span>{{ $t('btn.insert') }}</span>
              </v-tooltip>

              <v-tooltip
                top
                color="primary"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    fab
                    x-small
                    v-bind="attrs"
                    color="primary"
                    outlined
                    class="ml-5"
                    :disabled="!model.intereses.id"
                    v-on="on"
                    @click="showRemplace('intereses', model.intereses)"
                  >
                    <v-icon>{{ icons.mdiFileReplaceOutline }}</v-icon>
                  </v-btn>
                </template>
                <span>{{ $t('btn.remplace') }}</span>
              </v-tooltip>

              <v-tooltip
                top
                color="primary"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    fab
                    x-small
                    v-bind="attrs"
                    color="primary"
                    outlined
                    class="ml-5"
                    :disabled="!model.intereses.id"
                    v-on="on"
                    @click="showAsociate('intereses', model.intereses)"
                  >
                    <v-icon>{{ icons.mdiLinkVariantPlus }}</v-icon>
                  </v-btn>
                </template>
                <span>{{ $t('btn.asociate') }}</span>
              </v-tooltip>

              <v-tooltip
                top
                color="error"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    fab
                    x-small
                    v-bind="attrs"
                    color="error"
                    outlined
                    class="ml-5"
                    v-on="on"
                    @click="showDelete('intereses', model.intereses.name)"
                  >
                    <v-icon>{{ icons.mdiDeleteOutline }}</v-icon>
                  </v-btn>
                </template>
                <span>{{ $t('btn.delete') }}</span>
              </v-tooltip>
            </v-col>
          </v-row>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!-- CHANGE ROOM -->
    <v-dialog
      v-model="isChangeRooms"
      max-width="750px"
    >
      <v-card height="250px">
        <v-card-title>{{ `${$t('lbl.gestionChange')}` }}</v-card-title>
        <v-divider></v-divider>
        <v-card-text>
          <v-row
            v-if="model.rooms"
            class="mt-5"
          >
            <v-col
              cols="12"
              md="6"
            >
              <v-text-field
                v-model="model.rooms.name"
                :label="$t('lbl.room')"
                outlined
                dense
                hide-details
              >
              </v-text-field>
            </v-col>
            <v-col
              cols="12"
              md="6"
            >
              <v-autocomplete
                v-model="model.rooms.id"
                :items="itemsRooms"
                :search-input.sync="searchRooms"
                hide-details
                hide-selected
                :label="$t('lbl.room')"
                outlined
                dense
                item-text="name"
                item-value="id"
                clearable
                single-line
              >
                <template v-slot:no-data>
                  <v-list-item>
                    <v-list-item-title>
                      {{ $t('lbl.room') }}
                    </v-list-item-title>
                  </v-list-item>
                </template>
                <template v-slot:selection="{ item }">
                  <span v-text="item.name"></span>
                </template>
                <template v-slot:item="{ item }">
                  <v-list-item-content>
                    <v-list-item-title>{{ item.name }}</v-list-item-title>
                  </v-list-item-content>
                </template>
              </v-autocomplete>
            </v-col>
          </v-row>
        </v-card-text>
        <v-divider></v-divider>

        <v-card-actions>
          <v-row class="ml-2">
            <v-col
              cols="12"
              md="2"
            >
              <v-btn
                color="primary"
                outlined
                @click="isChangeRooms = !isChangeRooms"
              >
                {{ $t('btn.close') }}
              </v-btn>
            </v-col>
            <v-col
              v-if="model.rooms"
              cols="12"
              md="6"
              offset-md="2"
            >
              <v-tooltip
                top
                color="primary"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    fab
                    x-small
                    v-bind="attrs"
                    color="primary"
                    outlined
                    v-on="on"
                    @click="showAdd('rooms')"
                  >
                    <v-icon>{{ icons.mdiPlus }}</v-icon>
                  </v-btn>
                </template>
                <span>{{ $t('btn.insert') }}</span>
              </v-tooltip>

              <v-tooltip
                top
                color="primary"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    fab
                    x-small
                    v-bind="attrs"
                    color="primary"
                    outlined
                    class="ml-5"
                    :disabled="!model.rooms.id"
                    v-on="on"
                    @click="showRemplace('rooms', model.rooms)"
                  >
                    <v-icon>{{ icons.mdiFileReplaceOutline }}</v-icon>
                  </v-btn>
                </template>
                <span>{{ $t('btn.remplace') }}</span>
              </v-tooltip>

              <v-tooltip
                top
                color="primary"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    fab
                    x-small
                    v-bind="attrs"
                    color="primary"
                    outlined
                    class="ml-5"
                    :disabled="!model.rooms.id"
                    v-on="on"
                    @click="showAsociate('rooms', model.rooms)"
                  >
                    <v-icon>{{ icons.mdiLinkVariantPlus }}</v-icon>
                  </v-btn>
                </template>
                <span>{{ $t('btn.asociate') }}</span>
              </v-tooltip>

              <v-tooltip
                top
                color="error"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    fab
                    x-small
                    v-bind="attrs"
                    color="error"
                    outlined
                    class="ml-5"
                    v-on="on"
                    @click="showDelete('rooms', model.rooms.name)"
                  >
                    <v-icon>{{ icons.mdiDeleteOutline }}</v-icon>
                  </v-btn>
                </template>
                <span>{{ $t('btn.delete') }}</span>
              </v-tooltip>
            </v-col>
          </v-row>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>
import { mapMutations, mapState } from 'vuex'
// eslint-disable-next-line import/no-unresolved
import useAppConfig from '@core/@app-config/useAppConfig'
// eslint-disable-next-line import/no-unresolved
import AppCardCode from '@/@core/components/app-card-code/AppCardCode.vue'

import exportFromJSON from 'export-from-json'
import {
  mdiMagnify,
  mdiDeleteOutline,
  mdiSquareEditOutline,
  mdiPlus,
  mdiArrowLeft,
  mdiUpdate,
  mdiEyeOutline,
  mdiEyeOffOutline,
  mdiTrashCan,
  mdiAirplaneTakeoff,
  mdiCity,
  mdiPlaylistEdit,
  mdiCancel,
  mdiInformationOutline,
  mdiListStatus,
  mdiFileReplaceOutline,
  mdiLinkVariantPlus,
  mdiMenuDown,
  mdiMenuRight,
  mdiFileCode,
  mdiFileDownload,
} from '@mdi/js'
import Media from '@dongido/vue-viaudio'

export default {
  components: {
    Media,
    AppCardCode,
  },
  setup() {
    const { menuIsVerticalNavMini } = useAppConfig()

    return {
      menuIsVerticalNavMini,
    }
  },
  data() {
    return {
      isLoading: true,
      rutaPublic: process.env.VUE_APP_API_URL_PLUBLIC,
      nameB2B: process.env.VUE_APP_PROJ_NAME,
      rutaS3: process.env.VUE_APP_API_URL_S3_HOTEL,
      icons: {
        mdiMagnify,
        mdiDeleteOutline,
        mdiSquareEditOutline,
        mdiPlus,
        mdiArrowLeft,
        mdiUpdate,
        mdiEyeOutline,
        mdiEyeOffOutline,
        mdiTrashCan,
        mdiAirplaneTakeoff,
        mdiCity,
        mdiPlaylistEdit,
        mdiCancel,
        mdiInformationOutline,
        mdiListStatus,
        mdiFileReplaceOutline,
        mdiLinkVariantPlus,
        mdiMenuDown,
        mdiMenuRight,
        mdiFileCode,
        mdiFileDownload,
      },
      modelTimeConfig: {
        type: 'string',
        mask: 'HH:mm',
      },
      transmisiones: [],
      marcas: [],
      combustibles: [],
      caracteristicas: [],
      modelCaracteristicas: [],
      model: {},
      edad_min: {},
      loading: false,
      loadingDelete: false,

      items: [],

      isDialogVisible: false,
      isDialogVisibleDelete: false,
      isDialogVisibleDeleteItem: false,
      isDialogUpdate: false,
      nameOlds: [],
      newNameOld: {},
      modelConfig: {
        type: 'string',
        mask: 'YYYY-MM-DD',
      },
      itemsHotels: [],
      allHotels: [],
      searchHotel: null,

      itemsOperadores: [],
      allOperadores: [],
      searchOperador: null,

      itemsMarcas: [],
      allMarcas: [],
      listMarcas: [],
      searchMarcas: null,

      categories: [],
      itemsTipoDestino: [],
      allTipoDestino: [],
      searchTipoDestino: null,
      intereses: [],
      searchIntereses: null,
      itemsIntereses: [],
      modelIntereses: [],
      servicios: [],
      itemsServicios: [],
      searchServicios: null,
      modelServicios: [],
      galery: [],
      editGeoTag: true,
      address: {},
      edades: [],
      numeros: [],
      file: null,
      uploadingFile: false,
      processUpload: false,
      cancelarUpload: false,
      isChangeOperator: false,
      isChangeHotel: false,
      isAddChange: false,
      isAddRemplace: false,
      isAddAsociate: false,
      isAddDelete: false,
      isChangeMarca: false,
      isChangeCategoria: false,
      isChangeTipoDestino: false,
      isChangeEdad: false,
      isChangeServicios: false,
      isChangeIntereses: false,
      isChangeRooms: false,
      addChange: false,
      type: null,
      changing: false,
      lote: null,

      hotelsChange: [],
      operatorsChange: [],
      marcasAsociadaChange: [],
      categoriasChange: [],
      tiposDestinosChange: [],
      edadesChange: [],
      serviciosChange: [],
      interesesChange: [],
      roomsChange: [],
      logo: null,
      valueRemplace: null,
      valueCsv: null,
      idRemplace: null,
      itemsRooms: [],
      allRooms: [],
      searchRooms: null,

      iconito: null,
      iconSelect: null,
      iconos: [],
      itemsIcons: [],
      searchIcons: null,
      showRow: false,
      indexRow: -1,

      sampleData: [
        {
          Nombre: 'Hotel Sol Palmeras',
          Operador: 'Melia Hotels Cuba',
          'Marca Asociada': 'Sol',
          Categoría: '4',
          'Tipo de Destinos': 'Sol y playa',
          Intereses:
            'Viaje de familia,Reuniones y eventos,Sol y playas,Bodas y lunas de miel,Viaje todo incluido,Grupos de ocio e incentivos,Viajes de buceo,Viaje de parejas,Viaje para solteros',

          /* 'Sólo Adultos': 'No',
          Edad: '', */
          Servicios:
            'Servicios de fax,Servicios médicos,Galería de arte,Tienda de artesanía,Mini mercado,Parqueo,Servicios de taxis,Renta de motos,Renta de autos,Servicio de internet,Wi-Fi en el área del lobby,Habitaciones para no fumadores,Facilidades para late check-out,Servicio de lavandería,Llamadas al doctor 24 hrs al día,Jardines,Tiendas de souvenirs y regalos,Servicio de shuttle,Cuarto de equipaje,Grupo de seguridad interna 24 horas,Traslados privados en autos,Jacuzzi,Accesos a la playa,Sombrillas de playa y piscina,Duchas de playa y piscina,Tumbonas de playa y piscina,Servicio de salvavidas en la playa,Buro de turismo,Servicio de niñera,Paquetes de bodas,Gazebos para bodas,Paquetes de lunas de miel,Servicio de masajes,Sala de fitness climatizada,Solárium,Tratamientos de belleza,Idiomas del personal: Español, Inglés, Italiano, Francés y Alemán,No se aceptan mascotas,Programa de animación diaria,Música en vivo en la cena,Clases de español,Espectáculos de animación,Espectáculos profesionales',
          Room: '608',
          Restaurant: '9',
          Bar: '9',
          Pool: '3',
          Salones: '2',
          Descripción:
            'El Hotel Sol Palmeras es un bonito y acogedor Todo Incluido ubicado en Varadero, una de las playas más hermosas de la Isla. Con una posición privilegiada frente al mar, ofrece a sus huéspedes increíbles vistas de un espectacular paisaje y el agradable fresco de la brisa marina. Está diseñado en forma de herradura lo cual permite alcanzar mejor visibilidad de todas las atracciones que le rodean. Al respecto, el Sol Palmeras se encuentra próximo al Centro Comercial Plaza Américas y al Varadero Golf Club, y ello lo convierte en el hospedaje por excelencia para golfistas y sus familias.',
          País: 'Cuba',
          'Provincia/Estado': 'Matanzas',
          Localidad: 'Varadero',
          Dirección: 'Carretera Las Morlas, Varadero, Matanzas, Cuba. CP 42 200',
          Latitud: '23.176433',
          Longitud: '-81.201992',
          Contactos: 'John Doe*513 98754565x101x102;contacto@email.com, soporte@email.com / Miguel Pérez*513 89654621, 513 56564541;comercial@gmail.com',
          Entrada: '15:00',
          Salida: '12:00',
          Habitaciones:
            'Standard,Superior,Junior Suite,Suite,Bungalow (1 Habitación),Junior Suite Familiar,Bungalow Suite,Bungalow Familiar',
        },
      ],
      existRestautration: false,
    }
  },
  computed: {
    ...mapState({
      addressHotel: state => state.app.addressHotel,
      onlyShow: state => state.app.onlyShow,
    }),
  },
  watch: {
    searchHotel(val) {
      if (val !== null && val !== undefined) {
        // eslint-disable-next-line no-unused-expressions
        val && this.filterHotel(val.toLowerCase())
      } else {
        this.itemsHotels = []
      }
    },
    searchOperador(val) {
      if (val !== null && val !== undefined) {
        // eslint-disable-next-line no-unused-expressions
        val && this.filterOperador(val.toLowerCase())
      } else {
        this.itemsOperadores = []
      }
    },
    searchMarcas(val) {
      if (val !== null && val !== undefined) {
        // eslint-disable-next-line no-unused-expressions
        this.filterMarcas(val.toLowerCase())
      } else {
        this.itemsMarcas = this.allMarcas
      }
    },
    searchTipoDestino(val) {
      // eslint-disable-next-line no-unused-expressions
      this.filterTipoDestino(val.toLowerCase())
    },
    searchIcons(val) {
      if (val !== null && val !== undefined) {
        // eslint-disable-next-line no-unused-expressions
        val && this.filterIcons(val.toLowerCase())
      } else {
        this.itemsIcons = []
      }
    },
    searchServicios(val) {
      if (val !== null && val !== undefined) {
        // eslint-disable-next-line no-unused-expressions
        this.filterServicios(val.toLowerCase())
      }
    },
    searchIntereses(val) {
      if (val !== null && val !== undefined) {
        // eslint-disable-next-line no-unused-expressions
        this.filterIntereses(val.toLowerCase())
      }
    },
    searchRooms(val) {
      if (val !== null && val !== undefined) {
        // eslint-disable-next-line no-unused-expressions
        val && this.filterRooms(val.toLowerCase())
      } else {
        this.itemsRooms = []
      }
    },
  },
  mounted() {
    // PARA Q NAZCA EL MENU IZQ OCULTO
    this.menuIsVerticalNavMini = true
  },
  created() {
    this.getNomAll()
    this.getLote()
  },
  methods: {
    ...mapMutations([
      'adicionarImgHotels',
      'updateImgHotels',
      'adicionarContactHotel',
      'updateContactHotel',
      'updateAddressHotel',
      'updateGeoTag',
      'setOnlyShow',
    ]),
    showItem(index) {
      if (this.indexRow === index) {
        this.showRow = !this.showRow
      } else if (!this.showRow) {
        this.showRow = !this.showRow
      }
      this.indexRow = index
    },
    filterHotel(v) {
      this.itemsHotels = []
      if (v === '') {
        this.itemsHotels = []
      } else {
        this.itemsHotels = this.allHotels.filter(e => e.name.toLowerCase())
      }
    },
    filterOperador(v) {
      this.itemsOperadores = []
      if (v === '') {
        this.itemsOperadores = []
      } else {
        this.itemsOperadores = this.allOperadores.filter(e => e.name.toLowerCase())
      }
    },
    filterMarcas() {
      this.itemsMarcas = this.allMarcas.filter(e => e.name.toLowerCase())

      /* this.itemsMarcas = []
      if (v === '') {
        this.itemsMarcas = []
      } else {
        this.itemsMarcas = this.allMarcas.filter(e => e.name.toLowerCase())
      } */
    },
    filterTipoDestino() {
      this.itemsTipoDestino = this.allTipoDestino.filter(e => e.name.toLowerCase())
    },
    filterIcons(v) {
      this.itemsIcons = []
      if (v === '') {
        this.itemsIcons = []
      } else {
        this.itemsIcons = this.iconos.filter(e => e.name.toLowerCase())
      }
    },
    filterServicios(v) {
      this.itemsServicios = []
      if (v === '') {
        this.itemsServicios = []
      } else {
        this.itemsServicios = this.servicios.filter(e => e.name.toLowerCase())
      }
    },
    filterIntereses(v) {
      this.itemsIntereses = []
      if (v === '') {
        this.itemsIntereses = []
      } else {
        this.itemsIntereses = this.intereses.filter(e => e.name.toLowerCase())
      }
    },
    filterRooms(v) {
      this.itemsRooms = []
      if (v === '') {
        this.itemsRooms = []
      } else {
        this.itemsRooms = this.allRooms.filter(e => e.name.toLowerCase())
      }
    },
    getNomAll() {
      this.getExistRestore()
      this.getHotels()
      this.getOperadores()
      this.getMarcasAsociadas()
      this.getCategorias()
      this.getTipoDestino()

      // this.getEdades()
      this.getIcons()
      this.getServicios()
      this.getIntereses()
      this.getRooms()
    },
    getExistRestore() {
      const json = {
        product: 'hotels',
      }
      this.axios
        .post('lotes-restore/verify', json, {
          headers: {
            Authorization: `Bearer ${sessionStorage.getItem('sanctum_auth')}`,
          },
        })
        .then(res => {
          if (res.data.success) {
            if (res.data.data.status === 200) {
              this.existRestautration = true
            }
          }
        })
    },
    getHotels() {
      const json = {
        per_page: 1000,
      }
      this.axios
        .post('hotels/list', json, {
          headers: {
            Authorization: `Bearer ${sessionStorage.getItem('sanctum_auth')}`,
          },
        })
        .then(res => {
          this.allHotels = res.data.data
        })
    },
    getOperadores() {
      this.axios
        .get(`nom_operador_hotels?per_page=1000&perfil_slug=${sessionStorage.getItem('perfil')}`, {
          headers: {
            Authorization: `Bearer ${sessionStorage.getItem('sanctum_auth')}`,
          },
        })
        .then(res => {
          this.allOperadores = res.data.data
        })
    },
    setOperadores() {
      this.allMarcas = []
      if (this.model.marca_asociada.operador_id) {
        if (this.allOperadores.filter(e => e.id === this.model.marca_asociada.operador_id)[0].marcas.length > 0) {
          this.allMarcas = this.allOperadores.filter(e => e.id === this.model.marca_asociada.operador_id)[0].marcas
          this.itemsMarcas = this.allMarcas
        }
      }
    },
    getMarcasAsociadas() {
      this.axios
        .get(`nom_marca_asociada_hotels?per_page=1000&perfil_slug=${sessionStorage.getItem('perfil')}`, {
          headers: {
            Authorization: `Bearer ${sessionStorage.getItem('sanctum_auth')}`,
          },
        })
        .then(res => {
          this.allMarcas = res.data.data
          this.listMarcas = res.data.data
        })
    },
    getCategorias() {
      this.axios
        .get(`nom_categorias_hotels?per_page=1000&perfil_slug=${sessionStorage.getItem('perfil')}`, {
          headers: {
            Authorization: `Bearer ${sessionStorage.getItem('sanctum_auth')}`,
          },
        })
        .then(res => {
          this.categories = res.data.data
        })
    },
    getTipoDestino() {
      this.axios
        .get(`nom_tipo_destino_hotels?per_page=1000&perfil_slug=${sessionStorage.getItem('perfil')}`, {
          headers: {
            Authorization: `Bearer ${sessionStorage.getItem('sanctum_auth')}`,
          },
        })
        .then(res => {
          this.allTipoDestino = res.data.data
          this.itemsTipoDestino = res.data.data
        })
    },
    getIntereses() {
      this.axios
        .get(`nom_intereses_hotels?per_page=1000&perfil_slug=${sessionStorage.getItem('perfil')}`, {
          headers: {
            Authorization: `Bearer ${sessionStorage.getItem('sanctum_auth')}`,
          },
        })
        .then(res => {
          this.intereses = res.data.data
        })
    },
    getRooms() {
      this.axios
        .get(`nom_habitacion_hotels?per_page=1000&perfil_slug=${sessionStorage.getItem('perfil')}`, {
          headers: {
            Authorization: `Bearer ${sessionStorage.getItem('sanctum_auth')}`,
          },
        })
        .then(res => {
          this.allRooms = res.data.data
        })
    },
    getServicios() {
      this.axios
        .get(`nom_servicios_hotels?per_page=1000&perfil_slug=${sessionStorage.getItem('perfil')}`, {
          headers: {
            Authorization: `Bearer ${sessionStorage.getItem('sanctum_auth')}`,
          },
        })
        .then(res => {
          this.servicios = res.data.data
        })
    },
    setServicios() {
      if (this.model.servicios) {
        if (this.model.servicios.id) {
          const fff = this.servicios.filter(e => e.id === this.model.servicios.id)
          if (fff.length > 0) {
            this.itemsIcons.push(this.iconos.filter(e => e.name === fff[0].icon)[0])
            this.model.servicios.icon = fff[0].icon
            this.iconSelect = fff[0].icon
          }
        }
      }
    },
    clearServicio() {
      // this.iconSelect = null
      this.model.servicios.icon = null
    },
    getEdades() {
      this.axios
        .get(`nom_edad_hotels?per_page=1000&perfil_slug=${sessionStorage.getItem('perfil')}`, {
          headers: {
            Authorization: `Bearer ${sessionStorage.getItem('sanctum_auth')}`,
          },
        })
        .then(res => {
          this.edades = res.data.data
        })
    },

    getLote() {
      this.axios
        .get('lotes/hotels', {
          headers: {
            Authorization: `Bearer ${sessionStorage.getItem('sanctum_auth')}`,
          },
        })
        .then(res => {
          this.lote = res.data.data.data
          if (this.lote) {
            this.hotelsChange = res.data.data.data.hotels
            this.operatorsChange = res.data.data.data.operators
            this.marcasAsociadaChange = res.data.data.data.marcas
            this.categoriasChange = res.data.data.data.categorias
            this.tiposDestinosChange = res.data.data.data.tipos_destinos
            this.edadesChange = res.data.data.data.edades
            this.serviciosChange = res.data.data.data.servicios
            this.interesesChange = res.data.data.data.intereses
            this.roomsChange = res.data.data.data.rooms
          }
        })
        // eslint-disable-next-line no-return-assign
        .finally(() => (this.isLoading = false))
    },
    getIcons() {
      this.axios
        .get('nom_icons/mdi', {
          headers: {
            Authorization: `Bearer ${sessionStorage.getItem('sanctum_auth')}`,
          },
        })
        .then(res => {
          this.iconos = res.data.data
        })
    },
    changeIcons() {
      if (this.model.servicios.icon) {
        this.iconSelect = this.model.servicios.icon
      }
    },
    acceptUpload() {
      this.isDialogUpdate = true
    },
    canceleUpload() {
      this.isDialogVisibleDelete = true
    },
    confirmUpdateAll() {
      this.cancelarUpload = true
      this.axios
        .post(
          'lotes/process-all',
          { id: this.lote.id },
          {
            headers: {
              Authorization: `Bearer ${sessionStorage.getItem('sanctum_auth')}`,
            },
          },
        )
        .then(res => {
          if (res.data.success === false) {
            if (res.data.data.status === 401) {
              // this.$toast.error(this.$t('msg.noAutorice'))
            }
          } else {
            this.getLote()
            this.$toast.success(this.$t('msg.infoSuccess'))
          }
        })
        .finally(() => this.resetFile())
    },
    confirmCancele() {
      this.cancelarUpload = true
      this.axios
        .delete(`lotes/${this.lote.id}`, {
          headers: {
            Authorization: `Bearer ${sessionStorage.getItem('sanctum_auth')}`,
          },
        })
        .then(res => {
          if (res.data.success === false) {
            if (res.data.data.status === 401) {
              // this.$toast.error(this.$t('msg.noAutorice'))
            }
          } else {
            this.getLote()
            this.$toast.success(this.$t('msg.infoSuccess'))
          }
        })
        .finally(() => this.resetFile())
    },
    modalHotel(item) {
      this.type = 'hotel'
      this.model = {
        hotel: {
          name: item,
          valueCsv: item,
        },
      }
      this.isChangeHotel = true
    },
    modalOperator(item) {
      this.type = 'operador'
      this.model = {
        operador: {
          name: item,
          valueCsv: item,
        },
      }
      this.isChangeOperator = true
    },
    modalMarcaAsociada(item) {
      this.model = {
        marca_asociada: {
          name: item,
          operador_id: null,
          valueCsv: item,
        },
      }
      this.isChangeMarca = true
    },
    modalCategoria(item) {
      this.model = {
        categoria: {
          name: item,
          valueCsv: item,
        },
      }
      this.isChangeCategoria = true
    },
    modalTipoDestino(item) {
      this.model = {
        tipo_destino: {
          name: item,
          valueCsv: item,
        },
      }
      this.isChangeTipoDestino = true
    },
    modalEdad(item) {
      this.model = {
        edad: {
          name: item,
          valueCsv: item,
        },
      }
      this.isChangeEdad = true
    },
    modalServicios(item) {
      this.model = {
        servicios: {
          name: item,
          valueCsv: item,
          icon: null,
        },
      }
      this.isChangeServicios = true
    },
    modalIntereses(item) {
      this.model = {
        intereses: {
          name: item,
          valueCsv: item,
        },
      }
      this.isChangeIntereses = true
    },
    modalRoom(item) {
      this.model = {
        rooms: {
          name: item,
          valueCsv: item,
        },
      }
      this.isChangeRooms = true
    },
    showAdd(type) {
      this.type = type
      this.isAddChange = true
    },
    showRemplace(type, newValue) {
      this.type = type
      this.valueRemplace = newValue.name
      this.valueCsv = newValue.valueCsv
      this.idRemplace = newValue.id
      this.isAddRemplace = true
    },
    showAsociate(type, newValue) {
      this.type = type
      this.valueRemplace = newValue.name
      this.valueCsv = newValue.valueCsv
      this.idRemplace = newValue.id
      this.isAddAsociate = true
    },
    showDelete(type, newValue) {
      this.type = type
      this.valueRemplace = newValue
      this.isAddDelete = true
    },

    confirmChange(type) {
      this.axios
        .put(
          `lotes/update/${this.lote.id}`,
          { type, changes: this.model },
          {
            headers: {
              Authorization: `Bearer ${sessionStorage.getItem('sanctum_auth')}`,
            },
          },
        )
        // eslint-disable-next-line no-unused-vars
        .then(response => {
          this.$toast.success(this.$t('msg.infoSuccess'))
          this.getNomAll()
          this.getLote()
        })
        .catch(error => console.log(error))
        // eslint-disable-next-line no-return-assign
        .finally(() => this.resetFile())
    },
    confirmAddChange() {
      this.addChange = true
      if (this.type === 'operador' || this.type === 'marca_asociada') {
        const formData = new FormData()
        if (this.logo) {
          formData.append('logo', this.logo)
        }

        formData.append('data', JSON.stringify({ type: this.type, changes: this.model }))
        this.axios
          .post(`lotes/add-change-operador/${this.lote.id}`, formData, {
            headers: {
              Authorization: `Bearer ${sessionStorage.getItem('sanctum_auth')}`,
            },
          })
          // eslint-disable-next-line no-unused-vars
          .then(response => {
            this.$toast.success(this.$t('msg.infoSuccess'))
            this.getNomAll()
            this.getLote()
          })
          .catch(error => console.log(error))
          // eslint-disable-next-line no-return-assign
          .finally(() => this.resetFile())
      } else {
        this.axios
          .post(
            `lotes/add-change/${this.lote.id}`,
            { type: this.type, changes: this.model },
            {
              headers: {
                Authorization: `Bearer ${sessionStorage.getItem('sanctum_auth')}`,
              },
            },
          )
          // eslint-disable-next-line no-unused-vars
          .then(response => {
            this.$toast.success(this.$t('msg.infoSuccess'))
            this.getNomAll()
            this.getLote()
          })
          .catch(error => console.log(error))
          // eslint-disable-next-line no-return-assign
          .finally(() => this.resetFile())
      }
    },
    confirmAddRemplace() {
      this.addChange = true
      const json = {
        newValue: this.valueRemplace,
        valueCsv: this.valueCsv,
        idValue: this.idRemplace,
        type: this.type,
      }

      if (this.model.servicios) {
        if (this.model.servicios.icon) {
          json.icon = this.model.servicios.icon
        }
      }

      this.axios
        .post(`lotes/add-remplace/${this.lote.id}`, json, {
          headers: {
            Authorization: `Bearer ${sessionStorage.getItem('sanctum_auth')}`,
          },
        })
        // eslint-disable-next-line no-unused-vars
        .then(response => {
          this.$toast.success(this.$t('msg.infoSuccess'))
          this.getNomAll()
          this.getLote()
        })
        .catch(error => console.log(error))
        // eslint-disable-next-line no-return-assign
        .finally(() => this.resetFile())
    },
    confirmAddAsociate() {
      this.addChange = true
      const json = {
        newValue: this.valueRemplace,
        valueCsv: this.valueCsv,
        idValue: this.idRemplace,
        type: this.type,
      }

      if (this.model.servicios) {
        if (this.model.servicios.icon) {
          json.icon = this.model.servicios.icon
        }
      }

      this.axios
        .post(`lotes/add-asociate/${this.lote.id}`, json, {
          headers: {
            Authorization: `Bearer ${sessionStorage.getItem('sanctum_auth')}`,
          },
        })
        // eslint-disable-next-line no-unused-vars
        .then(response => {
          this.$toast.success(this.$t('msg.infoSuccess'))
          this.getNomAll()
          this.getLote()
        })
        .catch(error => console.log(error))
        // eslint-disable-next-line no-return-assign
        .finally(() => this.resetFile())
    },
    confirmAddDelete() {
      this.addChange = true
      const json = {
        newValue: this.valueRemplace,
        type: this.type,
      }

      this.axios
        .post(`lotes/add-delete/${this.lote.id}`, json, {
          headers: {
            Authorization: `Bearer ${sessionStorage.getItem('sanctum_auth')}`,
          },
        })
        // eslint-disable-next-line no-unused-vars
        .then(response => {
          this.$toast.success(this.$t('msg.infoSuccess'))
          this.getNomAll()
          this.getLote()
        })
        .catch(error => console.log(error))
        // eslint-disable-next-line no-return-assign
        .finally(() => this.resetFile())
    },
    deleteItem(item) {
      this.isDialogVisibleDeleteItem = true
      this.model = {
        name: {
          id: item.id,
          name: item.name.name,
        },
      }
    },
    confirmDelete() {
      this.loadingDelete = true
      const json = {
        id: this.model.name.id,
      }

      this.axios
        .post(`lotes/delete-hotel/${this.lote.id}`, json, {
          headers: {
            Authorization: `Bearer ${sessionStorage.getItem('sanctum_auth')}`,
          },
        })
        // eslint-disable-next-line no-unused-vars
        .then(res => {
          this.$toast.success(this.$t('msg.infoSuccess'))
          this.getNomAll()
          this.getLote()
        })
        .finally(() => this.resetFile())
    },

    setFile() {
      if (this.file) {
        this.uploadingFile = true
        const formData = new FormData()
        formData.append('file', this.file)
        this.axios
          .post('hotels/upload-lote', formData, {
            headers: {
              Authorization: `Bearer ${sessionStorage.getItem('sanctum_auth')}`,
            },
          })
          // eslint-disable-next-line no-unused-vars
          .then(response => {
            if (response.data.success) {
              this.$toast.success(this.$t('msg.verifiqueInfo'))
              this.getLote()
            } else if (response.data.data.status) {
              this.$toast.error(response.data.message)
            }
          })
          .catch(error => console.log(error))
          // eslint-disable-next-line no-return-assign
          .finally(() => this.resetFile())
      }
    },
    resetFile() {
      this.file = null
      this.model = {}
      this.logo = null
      this.type = null
      this.iconito = null
      this.iconSelect = null
      this.uploadingFile = false
      this.isDialogVisibleDeleteItem = false
      this.isDialogUpdate = false
      this.loadingDelete = false
      this.cancelarUpload = false
      this.isDialogVisibleDelete = false
      this.isChangeOperator = false
      this.isChangeHotel = false
      this.isChangeMarca = false
      this.isChangeCategoria = false
      this.isChangeTipoDestino = false
      this.isChangeEdad = false
      this.isChangeServicios = false
      this.isChangeIntereses = false
      this.isChangeRooms = false
      this.isAddChange = false
      this.isAddRemplace = false
      this.addChange = false
      this.valueRemplace = null
      this.valueCsv = null
      this.idRemplace = null
      this.isAddAsociate = false
      this.isAddDelete = false

      this.searchOperador = null
      this.searchMarcas = null
      this.searchTipoDestino = null
      this.searchServicios = null
      this.searchIcons = null
      this.searchIntereses = null
    },

    dowloadExportCsv() {
      this.exportCsv('Lote', this.sampleData, 'xls', this.fields)
    },

    exportCsv(newFileName, data, fileExportType) {
      const fileName = newFileName || 'exported-data'
      const exportType = exportFromJSON.types[fileExportType || 'csv']
      exportFromJSON({
        data,
        fileName,
        exportType,
      })
    },
  },
}
</script>
<style>
/**PARA OCULTAR LA FECHA DE HOY EN EL COMPONENTE DE HORA */
.vc-date-time .vc-date .vc-weekday,
.vc-date-time .vc-date .vc-month,
.vc-date-time .vc-date .vc-day,
.vc-date-time .vc-date .vc-year {
  display: none;
}
</style>
